import ChartsSectionSkeleton from 'components/ChartsSectionSkeleton'
import Layout from 'components/Common/Layout'
import TeamDetails from 'components/TeamDetails'
import TeamDetailsSkeleton from 'components/TeamDetailsSkeleton'
import TeamUsersSkeleton from 'components/TeamUsersSkeleton'
import { useEffect } from 'react'
import { createUseStyles } from 'react-jss'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import {
  fetchTeamAction,
  fetchTeamLearnersAction,
  getManagersListAction,
  getTeamLearnersRecapAction,
  getTeamRecapAction
} from './store'
import {
  usePagination,
  useSort
} from '@alphorm/ui'
import style from './style'

const useStyle = createUseStyles(style)
const sortField = 'FirstName'

export default () => {
  const dispatch = useDispatch()
  const { id } = useParams()
  const { page, rowsPerPage, skip } = usePagination()
  const { asc, field } = useSort(sortField)
  const { loading } = useSelector(state => state.config)
  const { chartSection } = useStyle()
  useEffect(() => {
    dispatch(fetchTeamAction(id))
    dispatch(getTeamRecapAction(id))
    dispatch(getTeamLearnersRecapAction(id))
    dispatch(getManagersListAction())
  }, [id])
  useEffect(() => {
    dispatch(fetchTeamLearnersAction(id, rowsPerPage, skip, asc, field))
  }, [id, rowsPerPage, skip, asc, field, page])
  return (
    <Layout menu='team'>
      {
        loading
          ? (
            <>
              <TeamDetailsSkeleton />
              <div className={chartSection}>
                <ChartsSectionSkeleton />
              </div>
              <TeamUsersSkeleton />
            </>
            )
          : <TeamDetails />
      }

    </Layout>
  )
}
