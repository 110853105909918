import {
  blue
} from '@alphorm/ui'

export default {
  imageContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    width: '100%'
  },
  image: {
    width: '60%'
  },
  text: {
    width: '70%'
  },
  contactCard: {
    display: 'flex',
    alignItems: 'center',
    marginTop: '26px'
  },
  contact: {
    display: 'flex',
    alignItems: 'center',
    boxShadow: '0 0 10px 0 rgba(194, 212, 230, 0.23)',
    height: 81,
    width: 270,
    marginRight: 16
  },
  icon: {
    fontSize: 25,
    color: blue[1],
    marginLeft: 24
  }
}
