export default {
  formClass: {
    height: 140,
    width: 232,
    marginTop: 24,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between'
  }
}
