import {
  Row,
  TypographyElement,
  Button,
  TextInput,
  ButtonSize,
  ButtonVariation,
  SelectList,
  Paper,
  neutral
} from '@alphorm/ui'
import { createUseStyles } from 'react-jss'
import style from './style'
import Text from './text.json'
import { AdminRole, annualLicenseType, monthlyLicenseType, trialLicenseType } from 'constants/'
import { useDispatch, useSelector } from 'react-redux'
import { validateLearner } from 'helpers/validator'
import { useFormik } from 'formik'
import { createLearnerAction } from 'pages/Learners/store'
import { useEffect, useState } from 'react'
import { fetchAllTeamsAction } from 'pages/Teams/store'
import { help } from 'assets'
import { scrollUp } from 'helpers'

const useStyle = createUseStyles(style)
const defaultNumberOfLicenses = 1
const maxLicensesBeforeDropDownSelect = 3
const defaultSelectItem = { Id: -1, Name: Text.other }
const labelStyle = {
  component: 'h4',
  variant: 'smallText',
  align: 'left',
  spacing: '0 0 4px 0',
  fontWeight: 'bold'
}

export default ({ licensesRemaining, selectedLicense, setOpenAffectLicensesModal, OrganizationLicenseDetails, setOrderLicenseIsOpen }) => {
  const LicenseDetails = (licenseType) => OrganizationLicenseDetails?.find(license => license?.OrganizationLicenseType === licenseType)
  const hideLicensesContainer = !LicenseDetails(annualLicenseType)?.LicenseTypeRemaining && !LicenseDetails(monthlyLicenseType)?.LicenseTypeRemaining && !LicenseDetails(trialLicenseType)?.LicenseTypeRemaining
  const dispatch = useDispatch()
  const {
    root,
    row,
    quantity,
    quantityButtonsStyle,
    messageContainer,
    helpIconClass,
    tipStyle,
    spanStyle,
    quantityRow,
    IntercomSpanStyle
  } = useStyle()
  const { user } = useSelector(({ identity }) => identity)
  const [teams, setTeams] = useState([])
  const [selectData, setSelectData] = useState({ selectedItemId: null, selectedItemValue: '' })
  const { handleSubmit, handleChange, resetForm, handleBlur, touched, errors, values } = useFormik({
    initialValues: {
      firstName: '',
      lastName: '',
      email: ''
    },
    validate: validateLearner,
    onSubmit: (values) => {
      scrollUp()
      dispatch(createLearnerAction(values.firstName, values.lastName, values.email, selectData?.selectedItemId, selectedLicense, quantityButton))
      resetForm()
      setOpenAffectLicensesModal(false)
    },
    enableReinitialize: true
  })
  if (!user?.role?.includes(AdminRole)) return null
  const { allTeams } = useSelector(state => state.teams)
  const { isManager, Id } = useSelector(({ organizationProfile }) => organizationProfile)
  useEffect(() => {
    dispatch(fetchAllTeamsAction(isManager ? Id : null))
  }, [])
  const [subscription, setSubscription] = useState({ LicenseTypeRemaining: 0 })
  const [quantityButton, setQuantityButton] = useState(defaultNumberOfLicenses)
  const [quantityRange, setQuantityRange] = useState(defaultSelectItem)

  useEffect(() => {
    const reformedData = allTeams?.Items?.map(element => {
      element.Value = element.Name
      return element
    })
    !isManager && reformedData?.unshift({
      Id: null,
      Value: Text.none
    })
    setTeams(reformedData)
  }, [allTeams])
  useEffect(() => {
    const reversedLicenseDetails = OrganizationLicenseDetails?.slice().reverse()
    reversedLicenseDetails?.map(license => {
      if (license.LicenseTypeRemaining && license.OrganizationLicenseType === selectedLicense) {
        setSubscription(license)
      }
      return license
    })
  }, [OrganizationLicenseDetails, selectedLicense])
  const quantityRanges = Array.from({ length: subscription.LicenseTypeRemaining }, (_, i) => ({ Id: i + 1, Name: String(i + 1) }))
  return (
    <div className={root}>
      {hideLicensesContainer
        ? (
          <Row>
            <Row className={row}>
              <TypographyElement
                component='h4'
                variant='smallText'
                align='left'
                spacing='16px 0 32px'
                fontSize={14}
              >
                {Text.noLicense}
              </TypographyElement>
              <Button
                dataTest='create-team-button'
                label={Text.noLicenseButton}
                width='100%'
                handleClick={() => {
                  setOpenAffectLicensesModal(false)
                  setOrderLicenseIsOpen(true)
                }}
              />
            </Row>
          </Row>)
        : (
          <Row>
            <Row className={quantityRow}>
              <TypographyElement
                component='h4'
                variant='smallText'
                align='left'
                spacing='20px 0 13px 0'
              >
                {Text.newLicense.licenseQuantity}
              </TypographyElement>
              <div className={quantity}>
                {quantityRanges.length > 0 &&
                  quantityRanges.slice(0, maxLicensesBeforeDropDownSelect).map(quantity =>
                    <Button
                      dataTest={`${quantity.Name}-license-button`}
                      key={quantity.Id}
                      backgroundColorHover='none'
                      variation={quantityButton === quantity.Id ? ButtonVariation.primary : ButtonVariation.secondary}
                      size={ButtonSize.big}
                      label={quantity.Name}
                      height={45}
                      handleClick={() => {
                        setQuantityButton(quantity.Id)
                        setQuantityRange(defaultSelectItem)
                      }}
                      className={quantityButtonsStyle}
                    />
                  )}
                {quantityRanges.length > maxLicensesBeforeDropDownSelect && (
                  <SelectList
                    dataTest='quantity-select-list'
                    label=''
                    valueField='Name'
                    selectedItem={quantityRange.Name}
                    onSelectedItem={(item) => {
                      setQuantityRange(item)
                      setQuantityButton(item.Id)
                    }}
                    items={quantityRanges.slice(maxLicensesBeforeDropDownSelect)}
                  />)}
              </div>
            </Row>
            <Row className={row}>
              <TextInput
                id='firstName'
                label={Text.firstName}
                value={values.firstName}
                type='text'
                placeholder={Text.firstName}
                status={(errors.firstName && touched.firstName) && 'error'}
                gutterBottom={8}
                typography={labelStyle}
                handleChange={handleChange}
                handleBlur={handleBlur}
              />
              <TextInput
                id='lastName'
                label={Text.lastName}
                type='text'
                placeholder={Text.lastName}
                value={values.lastName}
                status={(errors.lastName && touched.lastName) && 'error'}
                gutterBottom={8}
                typography={labelStyle}
                handleChange={handleChange}
                handleBlur={handleBlur}
              />
              <TextInput
                id='email'
                label={Text.email}
                type='text'
                placeholder={Text.email}
                value={values.email}
                status={(errors.email && touched.email) && 'error'}
                gutterBottom={16}
                typography={labelStyle}
                handleChange={handleChange}
                handleBlur={handleBlur}
                message={(errors.email && touched.email) && errors.email}
              />
              <SelectList
                id='SelectTeam'
                label={Text.label.team}
                items={teams}
                placeholder={Text.allTeams}
                typography={{ fontSize: 12, spacing: '0 0 4px' }}
                selectedItem={selectData?.selectedItemValue}
                gutterBottom={20}
                onSelectedItem={(item) => {
                  setSelectData({
                    selectedItemValue: item.Value,
                    selectedItemId: item.Id
                  })
                }}
              />
              <Button
                dataTest='create-team-button'
                disabled={!values || !selectData?.selectedItemValue}
                label={Text.createTeam}
                width='100%'
                handleClick={handleSubmit}
              />
            </Row>
          </Row>)}
      <Paper className={tipStyle}>
        <a className={messageContainer}>
          <img src={help} alt='helpIcon' className={helpIconClass} />
          <TypographyElement
            component='h4'
            variant='smallText'
            align='left'
            spacing='0 11px'
            fontSize={13}
            color={neutral[6]}
          >
            {Text.tip}
          </TypographyElement>
        </a>
        <TypographyElement
          component='h4'
          variant='smallText'
          align='left'
          spacing='0 0 16px 17px'
          fontSize={13}
          color={neutral[6]}
        >
          {Text.tipContent.body.first} <span onClick={() => window.Intercom('showNewMessage')} className={IntercomSpanStyle}>{Text.tipContent.link}</span>{Text.tipContent.body.second} <a href={`mailto:${Text.tipContent.email}`} className={spanStyle}>{Text.tipContent.email}</a> {Text.tipContent.body.last} <a href={`tel:${Text.tipContent.phone}`} className={spanStyle}>{Text.tipContent.phone}</a>
        </TypographyElement>
      </Paper>
    </div>
  )
}
