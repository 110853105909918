import Layout from 'components/Common/Layout'
import MyAccount from 'components/MyAccount'

export default () => {
  return (
    <>
      <Layout>
        <MyAccount />
      </Layout>
    </>

  )
}
