export default {
  container: {
    marginTop: '30px'
  },
  header: {
    display: 'flex',
    alignItems: 'center',
    gap: '10px'
  },
  iconHelp: {
    marginTop: 5
  }
}
