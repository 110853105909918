export default {
  learnerCardContainer: {
    width: '100%',
    border: '1px solid #D3DDE2',
    padding: 16,
    display: 'flex',
    borderRadius: 4,
    margin: '16px 0'
  }
}
