import { blue } from '@alphorm/ui'

export default {
  icon: {
    cursor: 'pointer',
    fontSize: 20,
    color: blue[0],
    transition: 'transform 0.2s ease'
  },
  iconContainer: {
    marginTop: 42,
    display: 'flex',
    alignItems: 'center'
  },
  teamInfosSkeleton: {
    display: 'flex',
    alignItems: 'center',
    width: '450px',
    margin: '0 16px'
  }
}
