import { blue, neutral } from '@alphorm/ui'

export default {
  icon: {
    color: blue[0],
    fontWeight: 200
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
    marginTop: 40
  },
  iconContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: 8,
    border: `.5px solid ${neutral[1]}`,
    cursor: 'pointer',
    height: 40,
    width: 40
  },
  titleStyle: {
    display: 'flex',
    marginTop: 40
  },
  help: {
    width: 26,
    height: 26,
    borderRadius: '50%',
    backgroundColor: neutral[1],
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginLeft: 12,
    margin: '40px 0',
    cursor: 'pointer'
  },
  iconHelp: {
    margin: '16px 0 0 0'
  },
  IntercomSpanStyle: {
    color: blue[0],
    textDecoration: 'none',
    cursor: 'pointer',
    fontWeight: 800
  },
  fontStyle: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    marginRight: 25,
    whiteSpace: 'nowrap'
  },
  paginationFooter: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginBottom: 30
  }
}
