import {
  TypographyElement,
  blue,
  red,
  useSort,
  usePagination,
  Button,
  Skeleton,
  Icon,
  Icons,
  neutral
} from '@alphorm/ui'
import { createUseStyles } from 'react-jss'
import style from './style'
import Text from './text.json'
import TeamCard from 'components/TeamCard'
import Layout from 'components/Common/Layout'
import { push } from 'connected-react-router'
import { useDispatch, useSelector } from 'react-redux'
import { TeamDetailsNeutralPath } from 'Routes'
import { useEffect, useState } from 'react'
import SearchBar from 'components/SearchBar'
import { fetchTeamsAction } from './store'
import { AdminRole, defaultSkip } from 'constants/'
import EmptyPage from 'components/EmptyPage'
import { TurnOnLoaderAction } from 'store/config'
import SlideBar from 'components/Common/SlideBar'
import CreateTeam from 'components/InviteBar/CreateTeam'
import DownloadReport from 'components/InviteBar/DownloadReport'
import DataTable from 'components/Common/DataTable'
import { ManagersRow, NameRow, TeamCountRow } from './LearnerRows'

const useStyle = createUseStyles(style)

const sortField = 'Name'
const defaultTeamsLength = 7

export default () => {
  const {
    learnersBox,
    learnersInfo,
    getLicence,
    learners,
    headerContainer,
    firstPart,
    searchBar,
    titleClass,
    showMoreContainer,
    secondPart,
    icon,
    cardStyle,
    arrow,
    button,
    tableContainer
  } = useStyle()
  const dispatch = useDispatch()

  const [search, setSearch] = useState('')
  const { organizationId } = useSelector(state => state.organization)
  const { data, count, TeamsWithNoManagers, TeamsWithNoLearners, Total } = useSelector(state => state.teams)
  const { loading } = useSelector(state => state.config)
  const { page, rowsPerPage, setPage } = usePagination()
  const { field, setField, asc, setAsc } = useSort(sortField)
  const { user } = useSelector(({ identity }) => identity)
  const { isManager } = useSelector(({ organizationProfile }) => organizationProfile)
  useEffect(() => {
    dispatch(TurnOnLoaderAction())
    setPage(1)
  }, [])
  useEffect(() => {
    dispatch(fetchTeamsAction(organizationId, rowsPerPage * page, defaultSkip, asc, field, search, isManager ? user?.Id : null))
  }, [organizationId, page, rowsPerPage, asc, field, search, user?.Id])
  const handleSearch = value => {
    setSearch(value)
    setPage(0)
  }
  const isAdmin = user?.role?.includes(AdminRole)
  const skeletonTeams = [...Array(count || defaultTeamsLength)]
  const [openCreateModal, setOpenCreateModal] = useState(false)
  const [openDownloadModal, setOpenDownloadModal] = useState(false)
  const config = {
    handleRowClick: (data) => {
      dispatch(push(`${TeamDetailsNeutralPath}/${data.Id}`))
      dispatch(TurnOnLoaderAction())
    },
    columns: [
      {
        property: 'Name',
        title: Text.name,
        sortable: true,
        render: (item, props, value) => {
          return (
            <NameRow value={value} />
          )
        }
      },
      {
        property: 'LearnersCount',
        title: Text.learnersCount,
        sortable: true,
        render: (item) => {
          return (
            <TeamCountRow item={item} />
          )
        }
      },
      {
        property: 'Managers',
        title: Text.managers,
        render: (item, props, value) => {
          return (
            <ManagersRow item={item} value={value} />
          )
        }
      }
    ]
  }
  return (
    <Layout>
      <SlideBar isOpen={openCreateModal} handleClose={() => setOpenCreateModal(false)}>
        <CreateTeam />
      </SlideBar>
      <SlideBar isOpen={openDownloadModal} handleClose={() => setOpenDownloadModal(false)}>
        <DownloadReport handleClose={() => setOpenDownloadModal(false)} />
      </SlideBar>
      {loading
        ? (
          <>
            <TypographyElement
              component='h2'
              variant='heading2'
              align='left'
              spacing='42px 0 30px 0'
              display='flex'
              className={titleClass}
            >
              {Text.teams}
            </TypographyElement>
            {
              isAdmin && (
                <div className={learnersBox}>
                  <Skeleton lines={1} height={184} width={363} />
                  <Skeleton lines={1} height={184} width={363} />
                  <Skeleton lines={1} height={184} width={363} />
                </div>
              )
            }
            <div className={headerContainer}>
              <div className={firstPart}>
                <Skeleton lines={1} height={45} width={300} />
              </div>
              <div className={secondPart}>
                <Skeleton lines={1} height={45} width={300} />
                <Skeleton lines={1} height={45} width={300} />
              </div>
            </div>
            {
              skeletonTeams?.map((team, index) => {
                return (
                  <div key={index}>
                    <TeamCard loading />
                  </div>
                )
              })
            }
          </>
          )
        : (
          <>
            <TypographyElement
              component='h2'
              variant='heading2'
              align='left'
              spacing='42px 0 30px 0'
              display='flex'
              className={titleClass}
            >
              {Text.teams}
            </TypographyElement>
            {
              Total
                ? (
                  <>
                    <div className={learnersBox}>
                      {
                        isAdmin
                          ? (
                            <>
                              <div className={learnersInfo}>
                                <TypographyElement
                                  component='h4'
                                  variant='heading4'
                                  align='left'
                                  spacing='20px 0 5px 0'
                                >
                                  {Text.allTeams}
                                </TypographyElement>
                                <TypographyElement
                                  component='h1'
                                  align='left'
                                  fontSize='60px'
                                  spacing='20px 0 21px 0'
                                  color={blue[0]}
                                  fontWeight={550}
                                >
                                  {Total}
                                </TypographyElement>
                              </div>
                              <div className={learnersInfo}>
                                <TypographyElement
                                  component='h4'
                                  variant='heading4'
                                  align='left'
                                  spacing='20px 0 5px 0'
                                >
                                  {Text.teamsWithoutManager}
                                </TypographyElement>
                                <div className={getLicence}>
                                  <TypographyElement
                                    component='h1'
                                    align='left'
                                    fontSize='60px'
                                    spacing='20px 0 30px 0'
                                    color={TeamsWithNoManagers ? red[3] : blue[0]}
                                    fontWeight={550}
                                  >
                                    {TeamsWithNoManagers}
                                  </TypographyElement>
                                </div>
                              </div>
                              <div className={learnersInfo}>
                                <TypographyElement
                                  component='h4'
                                  variant='heading4'
                                  align='left'
                                  spacing='20px 0 5px 0'
                                >
                                  {Text.emptyTeams}
                                </TypographyElement>
                                <div className={learners}>
                                  <TypographyElement
                                    component='h1'
                                    align='left'
                                    fontSize='60px'
                                    spacing='20px 15px 30px 0'
                                    color={TeamsWithNoLearners ? red[3] : blue[0]}
                                    fontWeight={550}
                                  >
                                    {TeamsWithNoLearners}
                                  </TypographyElement>
                                </div>
                              </div>
                            </>
                            )
                          : null
                      }
                    </div>
                    <div className={headerContainer}>
                      <div className={firstPart}>
                        <div className={searchBar}>
                          <SearchBar
                            height={45}
                            width={300}
                            label={search}
                            handleChange={handleSearch}
                          />
                        </div>
                      </div>
                      <div className={secondPart}>
                        <div
                          className={cardStyle}
                          onClick={() => setOpenDownloadModal(true)}
                        >
                          <Icon iconName={Icons.download} style={icon} />
                          <TypographyElement
                            component='p'
                            fontSize='13px'
                            lineHeight='16px'
                            spacing='0 0 0 8px'
                            color={neutral[6]}
                          >
                            {Text.downloadSuivi}
                          </TypographyElement>
                          <Icon iconName={Icons.roundedDown} style={arrow} />
                        </div>
                        {isAdmin
                          ? (
                            <Button
                              handleClick={() => setOpenCreateModal(true)}
                              size='big'
                              variation='primary'
                              className={button}
                              label={Text.createTeam}
                              width={200}
                            />)
                          : null}
                      </div>
                    </div>
                    <div className={tableContainer}>
                      <DataTable
                        data={data}
                        config={config}
                        handleSort={(e) => {
                          setField(e)
                          setAsc(!asc)
                        }}
                      />
                    </div>
                    {
                      data.length < count
                        ? (
                          <div className={showMoreContainer}>
                            <Button
                              handleClick={() => setPage(page + 1)}
                              size='big'
                              variation='secondary'
                              label={Text.showMore}
                              width={200}
                            />
                          </div>)
                        : null
                    }
                  </>
                  )
                : <EmptyPage
                    variant='team'
                    Title={isAdmin ? Text.title : Text.managerTitle}
                    SubTitle={isAdmin ? Text.subtitle : Text.managerSubTitle}
                    manager={!isAdmin}
                  />
            }
          </>)}
    </Layout>
  )
}
