import {
  TypographyElement,
  blue,
  red,
  ButtonSize,
  ButtonVariation,
  Button,
  Icon,
  Icons,
  neutral,
  usePagination,
  useSort,
  Modal,
  Skeleton
} from '@alphorm/ui'
import { createUseStyles } from 'react-jss'
import style from './style'
import Text from './text.json'
import UserCard from 'components/UserCard'
import Layout from 'components/Common/Layout'
import { useDispatch, useSelector } from 'react-redux'
import { push } from 'connected-react-router'
import { InvitedProfilePath, LearnerProfileNeutralPath } from 'Routes'
import { useEffect, useState } from 'react'
import SearchBar from 'components/SearchBar'
import {
  fetchLearnersAction,
  fetchInvitedLearnerInfosAction,
  resendWelcomeMessageAction,
  getNoActiveLicenseLearnersAction,
  fetchNeverConnectedLearnersAction
} from './store'
import { AdminRole, contactB2B, OnBoardingStateEnum, defaultSkip } from 'constants/'
import EmptyPage from 'components/EmptyPage'
import OnBoardingManager from 'pages/OnboardingContainer'
import { TurnOnLoaderAction } from 'store/config'
import DataTable from 'components/Common/DataTable'
import { CreationDateRow, EndDateRow, LastConnectionDateRow, NameRow, TeamNameRow } from './Rows'
import SlideBar from 'components/Common/SlideBar'
import DownloadLearnersReport from 'components/InviteBar/DownloadLearnersReport'
import AffectLicenses from 'components/InviteBar/AffectLicenses'
import OrderLicenses from 'components/InviteBar/OrderLicenses'
import AffectLicense from 'components/InviteBar/AffectLicense'
import { fetchLearnerProfileAction, sendNotificationAction, switchLastLicensePopupAction } from 'pages/LearnerProfile/store'
import { fetchListTeamsAction } from 'pages/Teams/store'
import moment from 'moment'
import LastLicenseModal from 'components/InviteBar/AffectLicense/LastLicenseModal'
import { InvitedManagerRow } from 'components/ManagerPage/Rows'

const useStyle = createUseStyles(style)

const skeletonLearners = [...Array(3)]

export const OrderLicenseModal = ({ orderLicenseIsOpen, setOrderLicenseIsOpen }) => {
  return (
    <Modal
      isOpen={orderLicenseIsOpen}
      handleClose={() => setOrderLicenseIsOpen(!orderLicenseIsOpen)}
    >
      <TypographyElement
        component='h4'
        variant='caption1'
        spacing='10px 16px 11px 16px'
      >
        {`${Text.contact.contactName} ${contactB2B.ContactName} ${Text.contact.in} ${contactB2B.ContactEmail} ${Text.contact.or} ${contactB2B.ContactPhone} ${Text.contact.forLicense}`}
      </TypographyElement>
    </Modal>
  )
}
const sortField = 'CreationDate'
const turnOffLoaderWhenDone = true

export default () => {
  const dispatch = useDispatch()
  const { data, Total, FilteredCount, TotalLearnersWithoutLicense, TotalLearnersNotConnected, OrganizationRemainingLicenses } = useSelector(state => state.learners)
  const { user } = useSelector(({ identity }) => identity)
  const { page, rowsPerPage, setPage } = usePagination()
  useEffect(() => {
    dispatch(getNoActiveLicenseLearnersAction())
  }, [TotalLearnersWithoutLicense])
  useEffect(() => {
    dispatch(fetchNeverConnectedLearnersAction())
    dispatch(fetchListTeamsAction())
  }, [])
  const { noLicenseLearners = [], neverConnectedLearners = [], lastResendWelcomeDate } = useSelector(({ learners }) => learners)
  const { field, setField, asc, setAsc } = useSort(sortField)
  const [search, setSearch] = useState('')
  const { isManager } = useSelector(({ organizationProfile }) => organizationProfile)
  const { organizationLicenseTypes = {} } = useSelector(({ referential }) => referential)
  const { onBoardingState } = useSelector(state => state.onboarding)
  const { loading } = useSelector(state => state.config)
  const { emailTypes } = useSelector(({ referential }) => referential)
  const neverConnectedLearnersIds = neverConnectedLearners?.map(learner => learner.Id)
  const { lastLicensePopupClosed } = useSelector(({ profile }) => profile)
  useEffect(() => { dispatch(TurnOnLoaderAction()) }, [])
  useEffect(() => {
    dispatch(fetchLearnersAction(turnOffLoaderWhenDone, rowsPerPage * page, defaultSkip, field, asc, search, isManager ? user?.Id : null))
  }, [page, rowsPerPage, field, asc, search, user?.Id])
  const handleSearch = value => {
    setSearch(value)
    setPage(0)
  }
  const {
    learnersBox,
    learnersInfo,
    getLicence,
    learners,
    icon,
    cardStyle,
    headerContainer,
    firstPart,
    searchBar,
    secondPart,
    userCardWrapper,
    paginationFooter,
    arrow,
    button
  } = useStyle({ loading })
  const [listUsers, setListUsers] = useState(data)
  const [orderLicenseIsOpen, setOrderLicenseIsOpen] = useState(false)
  const [openCreateModal, setOpenCreateModal] = useState(false)
  const [openDownloadModal, setOpenDownloadModal] = useState(false)
  const [isOpen, setIsOpen] = useState(false)
  useEffect(() => {
    setListUsers(data)
  }, [data])
  useEffect(() => {
    setIsOpen(!lastLicensePopupClosed)
  }, [lastLicensePopupClosed])
  const lastResendDate = moment().isBefore(moment(lastResendWelcomeDate).add(3, 'days'))
  const [openAffectLicensesModal, setOpenAffectLicensesModal] = useState(false)
  const [openAffectLicenseModal, setOpenAffectLicenseModal] = useState(false)
  const isAdmin = user?.role?.includes(AdminRole)
  const config = {
    costumRowRender: (item) => {
      return (
        <InvitedManagerRow item={item} learnerSide turnOffLoaderWhenDone={turnOffLoaderWhenDone} take={rowsPerPage * page} managerId={isManager ? user?.Id : null} />
      )
    },
    costumRowCondition: (item) => !!item.InvitationId && !isManager,
    handleRowClick: (data) => {
      dispatch(TurnOnLoaderAction())
      if (data.InvitationId) {
        dispatch(fetchInvitedLearnerInfosAction(data))
        dispatch(push(InvitedProfilePath))
      } else dispatch(push(`${LearnerProfileNeutralPath}/${data.Id}`))
    },
    columns: [
      {
        property: 'FirstName',
        title: Text.name,
        sortable: true,
        render: (item, props, value) => {
          return (
            <NameRow value={value} />
          )
        }
      },
      {
        property: 'TeamName',
        title: Text.team,
        render: (item) => {
          return (
            <TeamNameRow item={item} />
          )
        }
      },
      {
        property: 'CreationDate',
        title: Text.creationDate,
        sortable: true,
        render: (item) => {
          return (
            <CreationDateRow item={item} />
          )
        }
      },
      {
        property: 'LastConnectionDate',
        title: Text.lastConnectionDate,
        sortable: true,
        render: (item) => {
          return (
            <LastConnectionDateRow item={item} />
          )
        }
      },
      {
        property: 'EndDate',
        title: Text.endDate,
        sortable: true,
        render: (item, props, value) => {
          return (
            <EndDateRow item={item} value={value} organizationLicenseTypes={organizationLicenseTypes} lastResendDate={lastResendDate} />
          )
        }
      }
    ]
  }
  const handdleAffectLicenseButtonClick = () => {
    if (TotalLearnersWithoutLicense === 1 && noLicenseLearners.length) {
      dispatch(fetchLearnerProfileAction(noLicenseLearners[0]?.Id))
      setOpenAffectLicenseModal(true)
    } else {
      setOpenAffectLicensesModal(true)
    }
  }
  return (
    onBoardingState === OnBoardingStateEnum.Start
      ? <OnBoardingManager />
      : (
        <Layout>
          <LastLicenseModal
            openModal={isOpen}
            handleClose={() => {
              dispatch(sendNotificationAction(emailTypes.OrganizationLicensesDepleted))
              dispatch(switchLastLicensePopupAction(true))
              setIsOpen(false)
            }}
            handleSubmit={() => {
              dispatch(sendNotificationAction(emailTypes.OrganizationLicensesRequested))
              dispatch(switchLastLicensePopupAction(true))
              setIsOpen(false)
            }}
          />
          <SlideBar isOpen={openAffectLicenseModal} handleClose={() => setOpenAffectLicenseModal(false)}>
            <AffectLicense setModalOpen={setIsOpen} handleClose={() => setOpenAffectLicenseModal(false)} />
          </SlideBar>
          <SlideBar isOpen={openCreateModal} handleClose={() => setOpenCreateModal(false)}>
            <AffectLicenses setOrderLicenseIsOpen={setOrderLicenseIsOpen} title={Text.addLearnerTitle} setOpenAffectLicensesModal={setOpenCreateModal} showList />
          </SlideBar>
          <SlideBar isOpen={openDownloadModal} handleClose={() => setOpenDownloadModal(false)}>
            <DownloadLearnersReport handleClose={() => setOpenDownloadModal(false)} />
          </SlideBar>
          <SlideBar isOpen={orderLicenseIsOpen} handleClose={() => setOrderLicenseIsOpen(false)}>
            <OrderLicenses setOpenOrderLicenses={setOrderLicenseIsOpen} />
          </SlideBar>
          {
            loading
              ? (
                <div>
                  <TypographyElement
                    component='h2'
                    variant='heading2'
                    align='left'
                    spacing='42px 0 30px 0'
                  >
                    {Text.learners}
                  </TypographyElement>
                  <div className={learnersBox}>
                    <div className={learnersInfo}>
                      <Skeleton lines={2} height={[30, 20]} gap={20} margin='50px 0 7px 0' width={['60%', '30%']} />
                    </div>
                    {
                      isAdmin
                        ? (

                          <div className={learnersInfo}>
                            <Skeleton lines={2} height={[30, 20]} gap={20} margin='50px 0 7px 0' width={['60%', '30%']} />
                          </div>)
                        : null
                    }
                    <div className={learnersInfo}>
                      <Skeleton lines={2} height={[30, 20]} gap={20} margin='50px 0 7px 0' width={['60%', '30%']} />
                    </div>

                  </div>
                  <div className={headerContainer}>
                    <div className={firstPart}>
                      <div className={searchBar}>
                        <Skeleton lines={1} height={45} width={300} />
                      </div>
                    </div>
                    <div className={secondPart}>
                      <Skeleton lines={1} height={45} width={300} />
                      <Skeleton lines={1} height={45} width={300} />

                    </div>
                  </div>
                  <div className={userCardWrapper}>
                    {skeletonLearners.map((index) => {
                      return (
                        <div key={index}>
                          <UserCard loading />
                        </div>
                      )
                    })}
                  </div>
                </div>)
              : Total
                ? (
                  <div>
                    <SlideBar isOpen={openAffectLicensesModal} handleClose={() => setOpenAffectLicensesModal(false)}>
                      <AffectLicenses setOpenAffectLicensesModal={setOpenAffectLicensesModal} />
                    </SlideBar>
                    <TypographyElement
                      component='h2'
                      variant='heading2'
                      align='left'
                      spacing='42px 0 30px 0'
                    >
                      {Text.learners}
                    </TypographyElement>
                    <div className={learnersBox}>
                      <div className={learnersInfo}>
                        <TypographyElement
                          component='h4'
                          variant='heading4'
                          align='left'
                          spacing='20px 0 5px 0'
                        >
                          {Text.allLearners}
                        </TypographyElement>
                        <TypographyElement
                          component='h1'
                          align='left'
                          fontSize='60px'
                          spacing='20px 0 21px 0'
                          color={blue[0]}
                          fontWeight={550}
                        >
                          {Total}
                        </TypographyElement>
                      </div>
                      <div className={learnersInfo}>
                        {
                          isAdmin
                            ? (
                              <>
                                <TypographyElement
                                  component='h4'
                                  variant='heading4'
                                  align='left'
                                  spacing='20px 0 5px 0'
                                >
                                  {Text.learnersWithoutLicense}
                                </TypographyElement>
                                <div className={getLicence}>
                                  <TypographyElement
                                    component='h1'
                                    fontSize='60px'
                                    align='left'
                                    spacing='20px 0 21px 0'
                                    color={TotalLearnersWithoutLicense ? red[2] : blue[0]}
                                    fontWeight={550}
                                  >
                                    {TotalLearnersWithoutLicense}
                                  </TypographyElement>
                                  <div>
                                    <TypographyElement
                                      component='h4'
                                      variant='caption2'
                                      align='left'
                                      spacing='0 0 5px 0'
                                      color={red[2]}
                                    >
                                      {OrganizationRemainingLicenses} {Text.licenseLeft}
                                    </TypographyElement>
                                    {OrganizationRemainingLicenses && TotalLearnersWithoutLicense
                                      ? (
                                        <Button
                                          variation={ButtonVariation.secondary}
                                          width={220}
                                          color={red[2]}
                                          borderColor={red[2]}
                                          height={35}
                                          size={ButtonSize.small}
                                          label={Text.affectLicense}
                                          handleClick={() => {
                                            handdleAffectLicenseButtonClick()
                                          }}
                                        />)
                                      : (
                                        <Button
                                          variation={ButtonVariation.primary}
                                          width={220}
                                          height={35}
                                          size={ButtonSize.small}
                                          label={Text.orderLicense}
                                          handleClick={() => setOrderLicenseIsOpen(true)}
                                        />)}
                                  </div>
                                </div>
                              </>)
                            : (
                              <>
                                <TypographyElement
                                  component='h4'
                                  variant='heading4'
                                  align='left'
                                  spacing='20px 0 5px 0'
                                >
                                  {Text.courslessLearners}
                                </TypographyElement>
                                <div className={getLicence}>
                                  <TypographyElement
                                    component='h1'
                                    fontSize='60px'
                                    align='left'
                                    spacing='20px 0 21px 0'
                                    color={TotalLearnersWithoutLicense ? red[2] : blue[0]}
                                    fontWeight={550}
                                  >
                                    {TotalLearnersWithoutLicense}
                                  </TypographyElement>
                                </div>
                              </>)
                        }
                      </div>
                      <div className={learnersInfo}>
                        <TypographyElement
                          component='h4'
                          variant='heading4'
                          align='left'
                          spacing='20px 0 5px 0'
                        >
                          {Text.neverConnectedLearners}
                        </TypographyElement>
                        <div className={learners}>
                          <TypographyElement
                            component='h1'
                            align='left'
                            fontSize='60px'
                            spacing='20px 15px 12px 0'
                            color={TotalLearnersNotConnected ? red[2] : blue[0]}
                            fontWeight={550}
                          >
                            {TotalLearnersNotConnected}
                          </TypographyElement>
                          <div>
                            <TypographyElement
                              component='h4'
                              variant='caption2'
                              align='left'
                              spacing={TotalLearnersNotConnected ? '0 0 5px 0' : '0 0 34px'}
                              color={TotalLearnersNotConnected ? red[2] : blue[0]}
                            >
                              {Text.Learners}
                            </TypographyElement>
                            {isAdmin && TotalLearnersNotConnected
                              ? (
                                <Button
                                  variation={ButtonVariation.secondary}
                                  width={220}
                                  color={red[2]}
                                  borderColor={red[2]}
                                  height={35}
                                  disabled={lastResendDate}
                                  size={ButtonSize.small}
                                  label={Text.reinviteLearner}
                                  handleClick={() => dispatch(resendWelcomeMessageAction([...neverConnectedLearnersIds]))}
                                />)
                              : null}
                          </div>
                        </div>
                      </div>

                    </div>
                    <div className={headerContainer}>
                      <div className={firstPart}>
                        <div className={searchBar}>
                          <SearchBar
                            height={45}
                            width={300}
                            handleChange={handleSearch}
                            value={search}
                          />
                        </div>
                      </div>
                      <div className={secondPart}>
                        <div
                          className={cardStyle}
                          onClick={() => setOpenDownloadModal(true)}
                        >
                          <Icon iconName={Icons.download} style={icon} />
                          <TypographyElement
                            component='p'
                            fontSize='13px'
                            lineHeight='16px'
                            spacing='0 0 0 8px'
                            color={neutral[6]}
                          >
                            {Text.downloadSuivi}
                          </TypographyElement>
                          <Icon iconName={Icons.roundedDown} style={arrow} />
                        </div>
                        {isAdmin
                          ? (
                            <Button
                              handleClick={() => setOpenCreateModal(true)}
                              size='big'
                              variation='primary'
                              className={button}
                              label={Text.createTeam}
                              width={200}
                            />)
                          : null}
                      </div>
                    </div>
                    <div className={userCardWrapper}>
                      <DataTable
                        data={listUsers}
                        config={config}
                        handleSort={(e) => {
                          setField(e)
                          setAsc(!asc)
                        }}
                      />
                    </div>
                    {
                      listUsers.length < FilteredCount &&
                      (
                        <div className={paginationFooter}>
                          <Button
                            variation={ButtonVariation.secondary}
                            width={160}
                            height={53}
                            size={ButtonSize.small}
                            label={Text.showMore}
                            handleClick={() => !page ? setPage(page + 2) : setPage(page + 1)}
                          />
                        </div>
                      )
                    }
                  </div>)
                : (
                  <>
                    <TypographyElement
                      component='h2'
                      variant='heading2'
                      align='left'
                      spacing='42px 0 30px 0'
                    >
                      {Text.learners}
                    </TypographyElement>
                    <EmptyPage variant='learner' manager={!isAdmin} Title={isAdmin ? Text.title : Text.managerTitle} SubTitle={isAdmin ? Text.subTitle : Text.managerSubTitle} />
                  </>)
          }
        </Layout>)
  )
}
