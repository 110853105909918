export default {
  textClass: {
    marginBottom: 24
  },
  paginationFooter: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginBottom: 30
  }
}
