import { neutral } from '@alphorm/ui'
export default {
  TooltipWrapper: {
    position: 'relative'
  },
  toolTipStyle: {
    position: 'absolute',
    left: '73%',
    top: '6px',
    zIndex: '100',
    width: 'auto',
    whiteSpace: 'nowrap',
    backgroundColor: neutral[19],
    borderRadius: 4
  }
}
