export default {
  slidIn: {
    transition: 'max-height 0.2s ease-in-out',
    maxHeight: 200
  },
  slidOut: {
    maxHeight: 0,
    transition: 'max-height 0.2s ease-in-out',
    overflow: 'hidden'
  }
}
