import Layout from 'components/Common/Layout'
import {
  TypographyElement,
  Icon,
  Icons,
  neutral,
  Skeleton,
  useSort
} from '@alphorm/ui'
import { createUseStyles } from 'react-jss'
import style from './style'
import Text from './text.json'
import { useDispatch, useSelector } from 'react-redux'
import { useEffect } from 'react'
import { fetchAdminsAction } from './store'
import { useHistory } from 'react-router-dom'
import { help } from 'assets'
import ManagerCard from 'components/ManagerCard'
import { defaultField, helpLinks } from 'constants/'
import DataTable from 'components/Common/DataTable'
import { CreationDateRow, EmailRow, LastConnectionDateRow, NameRow } from './Rows'
import ReportAlertBanner from 'components/Common/ReportAlertBanner'

const useStyle = createUseStyles(style)
const defualtAdminLength = 7

export default () => {
  const { icon, container, titleStyle, iconContainer, iconHelp, IntercomSpanStyle } = useStyle()
  const dispatch = useDispatch()
  const { field, setField, asc, setAsc } = useSort(defaultField)
  useEffect(() => {
    dispatch(fetchAdminsAction(field, asc))
  },
  [field, asc])
  const { data } = useSelector(({ admins }) => admins)
  const history = useHistory()
  const { loading } = useSelector(state => state.config)
  const skeletonAdmins = [...Array(data.length || defualtAdminLength)]
  const config = {
    columns: [
      {
        property: 'FirstName',
        title: Text.name,
        sortable: true,
        render: (item, props, value) => {
          return (
            <NameRow value={value} />
          )
        }
      },
      {
        property: 'CreationDate',
        title: Text.creationDate,
        sortable: true,
        render: (item) => {
          return (
            <CreationDateRow item={item} />
          )
        }
      },
      {
        property: 'LastConnectionDate',
        title: Text.lastConnectionDate,
        sortable: true,
        render: (item) => {
          return (
            <LastConnectionDateRow item={item} />
          )
        }
      },
      {
        property: 'Email',
        title: Text.email,
        render: (item, props, value) => {
          return (
            <EmailRow value={value} />
          )
        }
      }
    ]
  }
  return (
    <Layout>
      <div className={container}>
        <div
          className={iconContainer}
          onClick={() => {
            history.goBack()
          }}
        >
          <Icon iconName={Icons.roundedLeft} style={icon} />
        </div>
        <div className={titleStyle}>
          <TypographyElement
            component='h2'
            variant='heading2'
            align='left'
            fontSize='29px'
            lignHeight='36px'
            display='inline'
            spacing='0 16px 0 0'
          >
            {Text.title}
          </TypographyElement>
          <a href={helpLinks.admins} target='_blank' rel='noreferrer'>
            <img src={help} alt='help' className={iconHelp} />
          </a>
        </div>
        {
          loading
            ? (
              <div>
                <Skeleton lines={1} height={83} width='100%' margin='0 0 30px 0' />
                {skeletonAdmins?.map((admin, index) => {
                  return (
                    <div key={index}>
                      <ManagerCard
                        loading={loading}
                      />
                    </div>
                  )
                })}
              </div>
              )
            : (
              <div>
                <ReportAlertBanner mode='horizontal' status='alert' width='100%'>
                  <TypographyElement
                    variant='body1'
                    fontSize='16px'
                    spacing='10px'
                    color={neutral[6]}
                  >
                    {Text.message.text} <span onClick={() => window.Intercom('showNewMessage')} className={IntercomSpanStyle}>{Text.message.link}</span>
                  </TypographyElement>
                </ReportAlertBanner>
                <br />
                <DataTable
                  data={data}
                  config={config}
                  handleSort={(e) => {
                    setField(e)
                    setAsc(!asc)
                  }}
                />
              </div>)
        }
      </div>
    </Layout>
  )
}
