import Layout from 'components/Common/Layout'
import ManagerDetailsPage from 'components/ManagerDetailsPage'

export default () => {
  return (
    <Layout>
      <ManagerDetailsPage />
    </Layout>
  )
}
