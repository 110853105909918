import {
  neutral
} from '@alphorm/ui'

export default {
  header: {
    display: 'flex',
    backgroundColor: neutral[0]
  },
  infos: {
    display: 'flex'
  },
  skeletonContainer: {
    width: 200
  }
}
