import {
  blue
} from '@alphorm/ui'

export default {
  root: {
    maxWidth: 380
  },
  icon: {
    fontSize: 16,
    color: blue[0]
  },
  row: {
    width: '100%',
    margin: '12px 0 20px 0'
  },
  iconHelp: {
    marginLeft: 4
  },
  linkHelp: {
    lineHeight: 0
  },
  titleClass: {
    alignItems: 'center'
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: 20,
    cursor: 'pointer',
    '&:hover p': {
      color: blue[0]
    }
  },
  slidIn: {
    transition: 'max-height 0.2s ease-in',
    maxHeight: 600
  },
  slidOut: {
    maxHeight: 0,
    display: 'none',
    transition: 'max-height 0.2s ease-in',
    '& $avatars': {
      opacity: 0
    },
    overflow: 'hidden'
  },
  checkBox: {
    width: '100% !important'
  },
  checkBoxContainer: {
    marginBottom: 24
  },
  messageContainer: {
    display: 'flex',
    textDecoration: 'none',
    padding: 16,
    alignItems: 'center'
  },
  helpIconClass: {
    width: 26
  },
  quantity: {
    display: 'flex',
    alignItems: 'flex-start',
    gap: 10
  },
  quantityButtonsStyle: {
    overflow: 'unset !important'
  },
  tipStyle: {
    display: 'flex',
    flexDirection: 'column',
    marginTop: 30
  },
  spanStyle: {
    color: blue[0],
    textDecoration: 'none'
  },
  quantityRow: {
    width: '100%',
    margin: '12px 0 20px 0',
    flexDirection: 'column'
  },
  IntercomSpanStyle: {
    color: blue[0],
    textDecoration: 'none',
    cursor: 'pointer'
  }
}
