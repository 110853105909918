import { teamDev } from 'assets'
export default
{
  id: 1,
  firstName: 'Yassine',
  lastName: 'Sebbar',
  email: 'yassine@test.com',
  image: 'https://static.alphorm.com/dev/upload/9f8f4041-acfe-4fcb-99b1-0545a6d35d1e.jpeg',
  team: 'Equipe Développement',
  expireDate: '05/07/2021',
  lastConnection: '01/01/2020',
  name: 'Yassine Sebar',
  users: 30,
  teamIcon: teamDev,
  teamId: 1
}
