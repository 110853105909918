import Text from '../text.json'
import style from './style'
import { createUseStyles } from 'react-jss'
import { monthlyLicenseType, annualLicenseType } from 'constants/'
import LicensceSelectItem from '../LicensceSelectItem'
import {
  TypographyElement
} from '@alphorm/ui'

const useStyle = createUseStyles(style)

export default ({ selectedLicense, setSelectedLicense }) => {
  const {
    licenseSelectContainer,
    header
  } = useStyle()
  return (
    <div>
      <div className={header}>
        <TypographyElement
          fontWeight={600}
          fontSize='13px'
          lineHeight='16px'
          margin='24px 0 0 0'
        >
          {Text.licenseType}
        </TypographyElement>
      </div>

      <div className={licenseSelectContainer}>
        <LicensceSelectItem selected={selectedLicense === annualLicenseType} info={Text.fullLicenseTypes.Annual} handleClick={() => setSelectedLicense(annualLicenseType)} />
        <LicensceSelectItem selected={selectedLicense === monthlyLicenseType} info={Text.fullLicenseTypes.Monthly} handleClick={() => setSelectedLicense(monthlyLicenseType)} />
      </div>
    </div>
  )
}
