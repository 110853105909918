import { blue } from '@alphorm/ui'

export default {
  controls: {
    display: 'flex',
    marginBottom: 24
  },
  coursesContainer: {
    maxHeight: 500,
    paddingRight: 5,
    overflow: 'auto',
    '&::-webkit-scrollbar': {
      width: 5
    },
    '&::-webkit-scrollbar-thumb': {
      background: blue[1],
      borderRadius: 50
    }
  }
}
