import {
  neutral,
  blue
} from '@alphorm/ui'

export default ({
  searchBar: {
    width: '100%'
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: 20
  },
  learnersContainer: {
    width: '100%',
    marginTop: 16,
    maxHeight: 366,
    borderRadius: 4,
    padding: 16,
    border: `1px solid ${neutral[3]}`,
    overflowY: 'auto',
    '&::-webkit-scrollbar': {
      width: 7
    },
    '&::-webkit-scrollbar-thumb': {
      background: blue[1],
      borderRadius: 50
    }
  }
})
