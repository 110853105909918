import {
  blue,
  neutral
} from '@alphorm/ui'

export default {
  noLicensesStyle: {
    borderRadius: 4,
    backgroundColor: neutral[0],
    boxShadow: '0 0 36px 0 rgba(194,212,230,0.23)',
    marginTop: 10,
    padding: 24
  },
  contactInfoStyle: {
    borderRadius: 4,
    backgroundColor: neutral[0],
    boxShadow: '0 0 36px 0 rgba(194,212,230,0.23)',
    marginTop: 10,
    padding: 24,
    display: 'flex',
    cursor: 'pointer'
  },
  contactIcon: {
    fontSize: 16,
    color: blue[0],
    marginRight: 8,
    lineHeight: 0
  }
}
