import {
  TypographyElement,
  neutral,
  Button,
  SelectList
} from '@alphorm/ui'
import style from './style'
import { createUseStyles } from 'react-jss'
import Text from './text.json'
import LearnerCard from '../LearnerCard'
import { useDispatch, useSelector } from 'react-redux'
import { useEffect, useState } from 'react'
import { attachUserToTeamAction, deleteUserFromTeamAction } from 'pages/LearnerProfile/store'
import { scrollUp } from 'helpers'

const useStyle = createUseStyles(style)

const NoTeam = {
  Id: 0,
  Name: Text.noTeam
}

export default ({ setOpenEditTeamModal }) => {
  const { Photo, FirstName, LastName, TeamId = '', Id, TeamName = '' } = useSelector(({ profile }) => profile)
  const { list } = useSelector(state => state.teams)
  const [selectData, setSelectData] = useState(TeamId ? { Id: TeamId, Name: TeamName } : NoTeam)
  useEffect(() => {
    if (TeamId) setSelectData({ Id: TeamId, Name: TeamName })
    else setSelectData(NoTeam)
  }, [TeamId])
  const dispatch = useDispatch()
  const {
    container
  } = useStyle()
  return (
    <div className={container}>
      <TypographyElement
        component='h3'
        variant='heading3'
        align='left'
      >
        {Text.title}
      </TypographyElement>
      <LearnerCard Photo={Photo} FirstName={FirstName} LastName={LastName} TeamName={TeamName} />
      <TypographyElement
        fontWeight={600}
        fontSize='16px'
        lineHeight='20px'
        spacing='0px 0px 16px 8px'
        color={neutral[6]}
      >
        {Text.teamName}
      </TypographyElement>
      <SelectList
        dataTest='change-team-select'
        width='100%'
        id='country'
        selectedItem={selectData?.Name}
        items={[...list, NoTeam]}
        valueField='Name'
        placeholder={Text.allTeams}
        name='country'
        onSelectedItem={(item) => setSelectData(item)}
      />
      <Button
        handleClick={() => {
          scrollUp()
          TeamId && selectData?.Id === NoTeam.Id ? dispatch(deleteUserFromTeamAction(Id, TeamId)) : dispatch(attachUserToTeamAction(Id, selectData, TeamId))
          setOpenEditTeamModal(false)
        }}
        width='100%'
        marginButton='32px 0 0 0'
        height={54}
        label={Text.edit}
      />

    </div>
  )
}
