export default {
  root: {
    maxWidth: '100% !important'
  },
  chartsContainer: {
    display: 'flex',
    gap: 20
  },
  bannerContainer: {
    marginBottom: 16
  }
}
