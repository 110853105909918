export default {
  cardsContainer: {
    display: 'flex',
    marginTop: 24,
    columnGap: 20
  },
  skeletonSection: {
    marginTop: 18
  }
}
