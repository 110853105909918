import Text from './text.json'
import { TypographyElement, Icon, Icons, Button } from '@alphorm/ui'
import { createUseStyles } from 'react-jss'
import style from './style'
import { contactB2B, helpLinks } from 'constants/'
import { useState } from 'react'
import SlideBar from 'components/Common/SlideBar'
import AffectLicenses from 'components/InviteBar/AffectLicenses'
import CreateTeam from 'components/InviteBar/CreateTeam'
import InviteManager from 'components/InviteBar/InviteManager'
import { useSelector } from 'react-redux'

const useStyle = createUseStyles(style)

const Cards = ({ contactCard, contact, icon }) => {
  return (
    <div className={contactCard}>
      <div className={contact}>
        <Icon iconName={Icons.smartPhone} style={icon} />
        <TypographyElement
          variant='caption1'
          fontSize='16px'
          align='left'
          spacing='17px 0 16px 16px'
          fontWeight='bold'
          Component='a'
          cursor='pointer'
          href={`tel:${contactB2B.ContactPhone}`}
        >
          {contactB2B.ContactPhone}
        </TypographyElement>
      </div>
      <div className={contact}>
        <Icon iconName={Icons.envelope} style={icon} />
        <TypographyElement
          variant='caption1'
          fontSize='16px'
          align='left'
          spacing='17px 0 16px 16px'
          fontWeight='bold'
          Component='a'
          cursor='pointer'
          href={`mailto:${contactB2B.ContactEmail}`}
        >
          {contactB2B.ContactEmail}
        </TypographyElement>
      </div>
    </div>
  )
}

export default ({ Title, SubTitle, children, hide, report, manager, variant }) => {
  const { container, messageContainer, button, contactCard, contact, icon } = useStyle({ hide, report, manager })
  const { isManager } = useSelector(({ organizationProfile }) => organizationProfile)
  const [openCreateModal, setOpenCreateModal] = useState(false)
  const [openCreateTeamModal, setOpenCreateTeamModal] = useState(false)
  const [openInviteModal, setOpenInviteModal] = useState(false)
  const emptyPagesVariants = {
    learner: {
      action: (e) => setOpenCreateModal(e),
      label: Text.createLearner
    },
    team: {
      action: (e) => setOpenCreateTeamModal(e),
      label: Text.createTeam
    },
    manager: {
      action: (e) => setOpenInviteModal(e),
      label: Text.inviteManager
    }
  }
  return (
    <>
      <SlideBar isOpen={openCreateModal} handleClose={() => setOpenCreateModal(false)}>
        <AffectLicenses title={Text.addLearnerTitle} setOpenAffectLicensesModal={setOpenCreateModal} showList />
      </SlideBar>
      <SlideBar isOpen={openCreateTeamModal} handleClose={() => setOpenCreateTeamModal(false)}>
        <CreateTeam />
      </SlideBar>
      <SlideBar helplink={helpLinks.manager} isOpen={openInviteModal} handleClose={() => setOpenInviteModal(false)}>
        <InviteManager />
      </SlideBar>
      <div className={messageContainer}>
        <TypographyElement
          component='h3'
          variant='heading3'
          fontSize='24px'
          lineHeight='38px'
        >
          {report ? Text.ReportTitle : (Title || Text.Title)}
        </TypographyElement>
        <TypographyElement
          component='h4'
          variant='caption1'
          fontSize='16px'
          align='center'
          lineHeight='26px'
        >
          {report ? Text.ReportSubTitle : (SubTitle || Text.SubTitle)}
        </TypographyElement>
        {report
          ? <Cards contactCard={contactCard} contact={contact} icon={icon} />
          : (variant && !isManager)
              ? (
                <Button
                  handleClick={() => emptyPagesVariants[variant].action(true)}
                  size='big'
                  variation='primary'
                  className={button}
                  label={emptyPagesVariants[variant].label}
                  width={200}
                />)
              : null}
      </div>
      <div className={container}>
        <div>{children}</div>
      </div>
    </>
  )
}
