import { blue } from '@alphorm/ui'

export default {
  checkBox: {
    width: '120px !important'
  },
  firstPart: {
    width: 490,
    display: 'flex',
    alignItems: 'center'
  },
  secendPart: {
    width: '60%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between'
  },
  icon: {
    fontSize: 16,
    color: blue[1]
  },
  selectItem: {
    width: 100
  },
  selectItemMoreOptions: {
    width: 200
  },
  searchBar: {
    width: 100
  },
  cardStyle: {
    height: 45,
    width: 177,
    borderRadius: 4,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: `${blue[1]}2D`,
    cursor: 'pointer'
  },
  headerContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: '48px 24px 24px 24px'
  },
  profile: {
    padding: 24
  }
}
