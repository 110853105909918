import Layout from 'components/Common/Layout'
import OnBoardingManager from 'pages/OnboardingContainer'
import { OnBoardingStateEnum, helpLinks, totalViewQueryFields } from 'constants/'
import { useDispatch, useSelector } from 'react-redux'
import { createUseStyles } from 'react-jss'
import style from './style'
import NoLicenseInfoCard from 'components/NoLicenseInfoCard'
import LastAffectedLearnersInfoCard from 'components/LastAffectedLearnersInfoCard'
import LastConnectionInfoCard from 'components/LastConnectionInfoCard'
import CoursesDoneInfoCard from 'components/CoursesDoneInfoCard'
import { useEffect } from 'react'
import ChartsSection from 'components/ChartsSection'
import { initHomePageAction } from 'pages/Learners/store'
import EmptyPage from 'components/EmptyPage'
import Recommendation from 'components/Recommendation'
import ChartsSectionSkeleton from 'components/ChartsSectionSkeleton'
import LicenseInfoCardSkeleton from 'components/LicenseInfoCardSkeleton'
import LastConnectedSkeleton from 'components/LastConnectionInfoCard/LastConnectedSkeleton'
import CoursesDoneSkeleton from 'components/CoursesDoneSkeleton'
import RecommendationSkeleton from 'components/RecommendationSkeleton'

const useStyle = createUseStyles(style)

export default () => {
  const dispatch = useDispatch()
  const { cardsContainer, skeletonSection } = useStyle()
  useEffect(() => {
    dispatch(initHomePageAction())
  }, [])
  const id = useSelector(state => state.organization).Id
  const { onBoardingState } = useSelector(state => state.onboarding)
  const loading = useSelector(({ config }) => config.loading)
  const Total = useSelector(({ learners }) => learners.Total)
  return (
    onBoardingState === OnBoardingStateEnum.Start
      ? <OnBoardingManager />
      : (
        <Layout>
          {
            loading
              ? (
                <div className={skeletonSection}>
                  <ChartsSectionSkeleton />
                  <div className={cardsContainer}>
                    <LicenseInfoCardSkeleton />
                    <LicenseInfoCardSkeleton />
                  </div>
                  <div className={cardsContainer}>
                    <LastConnectedSkeleton />
                    <CoursesDoneSkeleton />
                  </div>
                  <RecommendationSkeleton />
                </div>
                )
              : Total
                ? (
                  <>
                    <ChartsSection id={id} field={totalViewQueryFields.organization} infoLink={helpLinks.homePage} showLearnersActions />
                    <div className={cardsContainer}>
                      <NoLicenseInfoCard />
                      <LastAffectedLearnersInfoCard />
                    </div>
                    <div className={cardsContainer}>
                      <LastConnectionInfoCard />
                      <CoursesDoneInfoCard />
                    </div>
                    <Recommendation />
                  </>)
                : <EmptyPage variant='learner' />
          }
        </Layout>)
  )
}
