import OnBoardingAdmin from 'pages/OnBoardingAdmin'
import Onboarding from 'pages/Onboarding'
import { useSelector } from 'react-redux'
import { oldCostumer } from 'constants/'

let accountType = ''

export default ({ isInvited = false }) => {
  const { Description } = useSelector(({ organization }) => organization)
  accountType = Description
  switch (accountType) {
    case oldCostumer:
      return <Onboarding />
    case null:
    case undefined:
      return <OnBoardingAdmin isInvited={isInvited} />
    default:
      return null
  }
}
