import Text from '../text.json'
import style from './style'
import { createUseStyles } from 'react-jss'
import SearchBar from 'components/SearchBar'
import { useEffect, useState } from 'react'
import LearnerSelectItem from '../LearnerSelectItem'
import {
  TypographyElement,
  Button
} from '@alphorm/ui'
import { amazonBucket } from 'constants/'
import { affectBulkLicensesAction } from 'pages/Learners/store'
import { useDispatch } from 'react-redux'
import { scrollUp } from 'helpers'

const useStyle = createUseStyles(style)

export default ({ learnersList, licensesRemaining, selectedLearners, setSelectedLearners, selectedLicense, setOpenAffectLicensesModal }) => {
  const {
    searchBar,
    learnersContainer,
    header
  } = useStyle()
  const [learnersUpdatedList, setLearnersUpdatedList] = useState(learnersList)
  const handleAddItem = (learner) => {
    if (learner && !selectedLearners.includes(learner.Id)) {
      setSelectedLearners([...selectedLearners, learner.Id])
    }
  }
  useEffect(() => {
    setLearnersUpdatedList(learnersList)
  }, [learnersList.length])
  const handleRemoveItem = (learner) => {
    const updatedItems = selectedLearners.filter(item => item !== learner.Id)
    setSelectedLearners(updatedItems)
  }
  const [search, setSearch] = useState('')
  const handleSearch = value => {
    const filteredData = learnersList.filter(item =>
      (item.FirstName && item.FirstName.toLowerCase().includes(value.toLowerCase())) ||
      (item.LastName && item.LastName.toLowerCase().includes(value.toLowerCase())) ||
      (item.Email && item.Email.toLowerCase().includes(value.toLowerCase()))
    )
    setLearnersUpdatedList(filteredData)
    setSearch(value)
  }
  const dispatch = useDispatch()
  return (
    <div>
      <div className={header}>
        <TypographyElement
          fontWeight={600}
          fontSize='13px'
          lineHeight='16px'
          margin='24px 0 0 0'
        >
          {Text.learners}
        </TypographyElement>
        <TypographyElement
          fontWeight={600}
          fontSize='13px'
          lineHeight='16px'
          margin='24px 0 0 0'
          color='#9CAEC1'
        >
          {`(${selectedLearners.length}/${licensesRemaining} ${Text.selected}`}
        </TypographyElement>
      </div>
      <div className={searchBar}>
        <SearchBar
          label={Text.searchPlaceHolders}
          height={45}
          handleChange={handleSearch}
          value={search}
        />
      </div>
      <div className={learnersContainer}>
        {learnersUpdatedList.map((learner, key) => {
          return (
            <LearnerSelectItem
              disabled={!selectedLearners.includes(learner.Id) && selectedLearners.length >= licensesRemaining}
              key={key}
              selected={selectedLearners.includes(learner.Id)}
              name={`${learner.FirstName} ${learner.LastName}`}
              image={learner.Photo ? `${amazonBucket.bucketBaseUrl}${learner.Photo}` : null}
              handleAdd={() => handleAddItem(learner)}
              handleRemove={() => handleRemoveItem(learner)}
            />
          )
        })}
      </div>
      <Button
        handleClick={() => {
          scrollUp()
          dispatch(affectBulkLicensesAction({ learnersIds: [...selectedLearners], organizationLicenseType: selectedLicense }))
          setOpenAffectLicensesModal(false)
        }}
        disabled={!selectedLearners.length}
        width='100%'
        marginButton='32px 0 0 0'
        height={54}
        label={Text.affect}
      />
    </div>
  )
}
