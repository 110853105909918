import {
  TypographyElement
} from '@alphorm/ui'
import { createUseStyles } from 'react-jss'
import style from './style'
import Text from './text.json'
import { useDispatch, useSelector } from 'react-redux'
import { fetchLicenseInfoAction } from 'pages/Home/store'
import { useEffect, useState } from 'react'
import { annualLicenseType, monthlyLicenseType, trialLicenseType } from 'constants/'
import LearnersSelectList from './LearnersSelectList'
import { getNoActiveLicenseLearnersAction } from 'pages/Learners/store'
import LicenseSelectList from './LicenseSelectList'
import RemainingLicenses from './RemainingLicenses'
import AddLearner from '../AddLearner'

const useStyle = createUseStyles(style)
const noLoaderAction = true

export default ({ setOpenAffectLicensesModal, showList, title, setOrderLicenseIsOpen }) => {
  const {
    root,
    header
  } = useStyle()
  const { OrganizationLicenseDetails = [], TotalLicensesRemaining } = useSelector(({ organization }) => organization.licenseInfo)
  const { noLicenseLearners = [] } = useSelector(({ learners }) => learners)
  const LicenseDetails = (licenseType) => OrganizationLicenseDetails?.find(license => license?.OrganizationLicenseType === licenseType)
  const dispatch = useDispatch()
  const [noLicenseLearnersState, setNoLicenseLearnersState] = useState(noLicenseLearners)
  useEffect(() => {
    dispatch(fetchLicenseInfoAction(noLoaderAction))
    dispatch(getNoActiveLicenseLearnersAction())
  }, [])
  const initialSelectedLicense = LicenseDetails(annualLicenseType)?.LicenseTypeRemaining
    ? annualLicenseType
    : LicenseDetails(monthlyLicenseType)?.LicenseTypeRemaining
      ? monthlyLicenseType
      : LicenseDetails(trialLicenseType)?.LicenseTypeRemaining
        ? trialLicenseType
        : null
  const [selectedLicense, setSelectedLicense] = useState(initialSelectedLicense)
  const [selectedLearners, setSelectedLearners] = useState([])
  const handleChangeSelectedLicense = (license) => {
    setSelectedLicense(license)
    setSelectedLearners([])
  }
  useEffect(() => {
    setNoLicenseLearnersState(noLicenseLearners)
  }, [noLicenseLearners.length])
  return (
    <div className={root}>
      <div className={header}>
        <TypographyElement
          component='h3'
          variant='heading3'
          align='left'
          display='flex'
        >
          {title || Text.title}
        </TypographyElement>
      </div>
      <RemainingLicenses OrganizationLicenseDetails={OrganizationLicenseDetails} TotalLicensesRemaining={TotalLicensesRemaining} />
      {LicenseDetails(annualLicenseType)?.LicenseTypeRemaining && LicenseDetails(monthlyLicenseType)?.LicenseTypeRemaining ? <LicenseSelectList selectedLicense={selectedLicense} setSelectedLicense={handleChangeSelectedLicense} /> : null}
      {showList
        ? (
          <AddLearner
            OrganizationLicenseDetails={OrganizationLicenseDetails}
            licensesRemaining={LicenseDetails(selectedLicense)?.LicenseTypeRemaining}
            selectedLicense={selectedLicense}
            setOpenAffectLicensesModal={setOpenAffectLicensesModal}
            setOrderLicenseIsOpen={setOrderLicenseIsOpen}
          />)
        : (
          <LearnersSelectList
            learnersList={[...noLicenseLearnersState]}
            licensesRemaining={LicenseDetails(selectedLicense)?.LicenseTypeRemaining}
            selectedLearners={selectedLearners}
            setSelectedLearners={setSelectedLearners}
            selectedLicense={selectedLicense}
            setOpenAffectLicensesModal={setOpenAffectLicensesModal}
          />)}

    </div>
  )
}
