import {
  neutral
} from '@alphorm/ui'

export default {
  modal: {
    width: 636,
    backgroundColor: neutral[0],
    borderRadius: 4
  },
  line: {
    backgroundColor: neutral[13],
    height: 1,
    border: 'none',
    marginBottom: 20,
    width: '90%'
  },
  image: {
    maxWidth: 283
  }
}
