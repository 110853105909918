import { createUseStyles } from 'react-jss'
import style from './style'
import InfoCard from 'components/InfoCard'
import { Skeleton } from '@alphorm/ui'
import { barChartSkeleton, radarChartSkeleton } from 'assets'

const useStyle = createUseStyles(style)

export default () => {
  const {
    cardsContainer,
    ChartSkeletonStyle
  } = useStyle()
  return (
    <>
      <Skeleton lines={1} height={30} width={200} />
      <div className={cardsContainer}>
        <InfoCard loading width='50%' noKnowMore>
          <Skeleton lines={1} height={20} width='50%' />
          <div className={ChartSkeletonStyle}><object type='image/svg+xml' data={barChartSkeleton}>svg-animation</object></div>
        </InfoCard>
        <InfoCard loading width='50%' noKnowMore>
          <div className={ChartSkeletonStyle}>
            <object type='image/svg+xml' data={radarChartSkeleton}>svg-animation</object>
          </div>
        </InfoCard>
      </div>
    </>
  )
}
