import React from 'react'
import { createUseStyles } from 'react-jss'
import style from './style'
import {
  TypographyElement,
  blue,
  TableHead,
  TableRow,
  TableBody,
  TableCell,
  Icon,
  Icons
} from '@alphorm/ui'

export default ({ config, data, handleSort }) => {
  const useStyle = createUseStyles(style)
  const { tableRow, tableStyle, icon, columnStyle, filterStyle, pointer } = useStyle({ config })
  return (
    <table className={tableStyle}>
      <TableHead>
        <TableRow>
          {
            config.columns.map((column, i) => {
              return (
                <TableCell key={i}>
                  <div onClick={() => column.sortable ? handleSort(column.property) : null} className={column.sortable ? pointer : columnStyle}>
                    <TypographyElement
                      color={blue[0]}
                      fontWeight={600}
                      fontSize='13px'
                      lineHeight='16px'
                      component='p'
                    >
                      {column.title}
                    </TypographyElement>
                    {column.sortable
                      ? (
                        <div className={filterStyle}>
                          <Icon iconName={Icons.hideShowPlan} style={icon} />
                        </div>)
                      : null}
                  </div>
                </TableCell>
              )
            }
            )
          }
        </TableRow>
      </TableHead>
      <TableBody>
        {
          data.map((item, i) => {
            return (
              (config.costumRowCondition && config.costumRowCondition(item))
                ? (
                  <>
                    {config.costumRowRender(item)}
                  </>)
                : (
                  <tr
                    onClick={(e) => {
                      if (e.target.dataset.div === 'modal') {
                        return
                      }
                      if (config.handleRowClick) config.handleRowClick(item)
                    }}
                    key={i}
                    className={tableRow}
                  >
                    {
                      config.columns.map((row, i) => (
                        <TableCell key={i} padding='default'>
                          {
                            row.render
                              ? row.render(item[row.property], { config, data }, item)
                              : item[row.property]
                          }
                        </TableCell>
                      ))
                    }
                  </tr>)
            )
          }
          )
        }
      </TableBody>
    </table>
  )
}
