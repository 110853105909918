import { createUseStyles } from 'react-jss'
import style from './style'
import InfoCard from 'components/InfoCard'
import { Bar, Radar } from 'react-chartjs-2'
import { options, radarOptions, radarPlugin } from 'helpers/home'
import Text from './text.json'
import {
  TypographyElement,
  blue,
  Icon,
  Icons,
  neutral,
  Button
} from '@alphorm/ui'
import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { AdminRole, dateTypesConstenants, MDYDateFormat } from 'constants/'
import moment from 'moment'
import { dateToStringFormat, radarChartFormat, secondsToMinsChartFormat, ToHours } from 'helpers'
import DateRange from 'components/Common/DateRange'
import { fetchBarChartDataAction } from './store'
import { fetchOrganizationCategoryPerformanceAction, setDateFilterAction } from 'pages/Home/store'
import SlideBar from 'components/Common/SlideBar'
import AffectLicenses from 'components/InviteBar/AffectLicenses'
import DownloadLearnersReport from 'components/InviteBar/DownloadLearnersReport'
import { downloadUserReportAction } from 'pages/LearnerProfile/store'
import OrderLicenses from 'components/InviteBar/OrderLicenses'

const useStyle = createUseStyles(style)

const generateBarChartData = (data, labelsAndDateTypes) => {
  const newData = []
  switch (labelsAndDateTypes.dateType) {
    case dateTypesConstenants.oneDay:
      labelsAndDateTypes.dates?.map((el, index) => {
        const indexOfLabel = Object.keys(data).findIndex(element => {
          return moment(element).isSame(el)
        })
        if (indexOfLabel >= 0) {
          return newData.push(data[Object.keys(data)[indexOfLabel]])
        } else {
          return newData.push(0)
        }
      })
      break
    case dateTypesConstenants.week:
      labelsAndDateTypes.dates?.map((el, index) => {
        const indexOfLabel = Object.keys(data).findIndex(element => {
          return moment(element).isSame(el)
        })
        if (indexOfLabel >= 0) {
          return newData.push(data[Object.keys(data)[indexOfLabel]])
        } else {
          return newData.push(0)
        }
      })
      break
    case dateTypesConstenants.month:
      labelsAndDateTypes.dates?.map((el, index) => {
        const indexOfLabel = Object.keys(data).findIndex(element => {
          return moment(element).isSame(el)
        })
        if (indexOfLabel >= 0) {
          return newData.push(data[Object.keys(data)[indexOfLabel]])
        } else {
          return newData.push(0)
        }
      })
      break
    case dateTypesConstenants.year:
      labelsAndDateTypes.dates?.map((el, index) => {
        const indexOfLabel = Object.keys(data).findIndex(element => {
          return moment(element).isSame(el, 'month') && moment(element).isSame(el, 'year')
        })
        if (indexOfLabel >= 0) {
          return newData.push(data[Object.keys(data)[indexOfLabel]])
        } else {
          return newData.push(0)
        }
      })
      break
    case dateTypesConstenants.moreThanYear:
      labelsAndDateTypes.dates?.map((el, index) => {
        const indexOfLabel = Object.keys(data).findIndex(element => {
          return moment(element).isSame(el, 'year')
        })
        if (indexOfLabel >= 0) {
          return newData.push(data[Object.keys(data)[indexOfLabel]])
        } else {
          return newData.push(0)
        }
      })
      break
    default:
      for (let i = 0; i < labelsAndDateTypes.labels.length; i++) {
        newData.push(data[i] || 0)
      }
      break
  }
  return newData
}

const getYears = (startDate, endDate) => {
  const years = []
  let startYear = moment(startDate).year()
  const endYear = moment(endDate).year()
  while (startYear <= endYear) {
    years.push(startYear++)
  }
  return years
}

const getMonthDays = (startDate, endDate) => {
  const days = []
  const dates = []
  const startDateMoment = moment(startDate)
  const endDateMoment = moment(endDate)
  while (startDateMoment.isSameOrBefore(endDateMoment)) {
    days.push(startDateMoment.format('DD MMM'))
    dates.push(startDateMoment.format(MDYDateFormat))
    startDateMoment.add(1, 'days')
  }
  return {
    label: days,
    date: dates
  }
}

const barChartFormat = (data = {}, labelsAndDateTypes = []) => {
  return {
    labels: [...labelsAndDateTypes.labels],
    datasets: [
      {
        radius: 0,
        data: generateBarChartData(data, labelsAndDateTypes),
        backgroundColor: [
          blue[0]
        ]
      }
    ]
  }
}

const getWeekDays = (startDate, endDate) => {
  const days = []
  const dates = []
  const startDateMoment = moment(startDate)
  const endDateMoment = moment(endDate)
  while (startDateMoment.isSameOrBefore(endDateMoment)) {
    dates.push(startDateMoment.format(MDYDateFormat))
    days.push(startDateMoment.locale('fr').format('dddd DD MMM'))
    startDateMoment.add(1, 'days')
  }
  return {
    label: days,
    date: dates
  }
}

const getYearMonths = (startDate, endDate) => {
  const months = []
  const dates = []
  const startDateMoment = moment(startDate)
  const endDateMoment = moment(endDate)
  while (startDateMoment.isSameOrBefore(endDateMoment)) {
    months.push(startDateMoment.locale('fr').format('MMMM').charAt(0).toUpperCase() + startDateMoment.locale('fr').format('MMMM').slice(1))
    dates.push(startDateMoment.format(MDYDateFormat))
    startDateMoment.add(1, 'month')
  }
  return {
    label: months,
    date: dates
  }
}

const generateDateTypeAndLabels = (startDate, endDate) => {
  const startDateMoment = moment(startDate)
  const endDateMoment = moment(endDate)
  const daysCount = {
    isYear: endDateMoment.add(1, 'days').diff(startDateMoment, 'years'),
    isMonth: endDateMoment.add(1, 'days').diff(startDateMoment, 'month'),
    days: moment(endDate).diff(moment(startDate), 'days')
  }
  switch (true) {
    case daysCount.days > 365:
      return {
        dateType: dateTypesConstenants.moreThanYear,
        labels: [...getYears(startDate, endDate)]
      }
    case daysCount.isYear <= 1 && daysCount.days > 30:
      return {
        dateType: dateTypesConstenants.year,
        labels: [...getYearMonths(startDate, moment(startDate).add(1, 'year')).label],
        dates: [...getYearMonths(startDate, moment(startDate).add(1, 'year')).date]
      }
    case daysCount.isMonth <= 1 && daysCount.days >= 7:
      return {
        dateType: dateTypesConstenants.month,
        labels: [...getMonthDays(startDate, moment(startDate).add(1, 'month')).label],
        dates: [...getMonthDays(startDate, moment(startDate).add(1, 'month')).date]
      }
    case daysCount.days < 7 && daysCount.days >= 1:
      return {
        dateType: dateTypesConstenants.week,
        labels: [...getWeekDays(startDate, moment(startDate).add(7, 'days')).label],
        dates: [...getWeekDays(startDate, moment(startDate).add(7, 'days')).date]
      }
    case moment(startDate).isSame(moment(), 'day') && moment(endDate).isSame(moment(), 'day'):
      return {
        dateType: dateTypesConstenants.oneDay,
        labels: [Text.today],
        dates: [moment(startDate).format(MDYDateFormat)]
      }
    case moment(startDate).isSame(moment().subtract(1, 'd'), 'day') && moment(endDate).isSame(moment().subtract(1, 'd'), 'day'):
      return {
        dateType: dateTypesConstenants.oneDay,
        labels: [Text.yesterday],
        dates: [moment(startDate).format(MDYDateFormat)]
      }
    default:
      return {
        dateType: dateTypesConstenants.oneDay,
        labels: [moment(startDate).locale('fr').format('dddd, Do MMMM YYYY')],
        dates: [moment(startDate).format(MDYDateFormat)]
      }
  }
}

export default ({ id, field, infoLink, showLearnersActions, showLearnerActions, fullName }) => {
  const {
    cardsContainer,
    textClass,
    containerActions,
    paperDate,
    calendarIconClass,
    barChartStyle,
    ChartSkeletonStyle,
    firstPart,
    secondPart,
    cardStyle,
    button,
    icon,
    iconButton,
    downloadButton,
    arrow
  } = useStyle()
  const dispatch = useDispatch()
  const now = moment()._d
  const yearAgo = moment(now).subtract(1, 'Y')._d
  const [from, setFromDate] = useState(yearAgo)
  const [to, setToDate] = useState(now)
  const handleSelect = values => {
    setFromDate(moment(values.selection.startDate)._d)
    setToDate(moment(values.selection.endDate)._d)
  }
  useEffect(() => {
    dispatch(fetchBarChartDataAction(id, field, moment(from).format(MDYDateFormat), moment(to).format(MDYDateFormat), generateDateTypeAndLabels(moment(from), moment(to)).dateType))
    dispatch(fetchOrganizationCategoryPerformanceAction(id, field, moment(from).format(MDYDateFormat), moment(to).format(MDYDateFormat), generateDateTypeAndLabels(moment(from), moment(to)).dateType))
    dispatch(setDateFilterAction(moment(from).format(MDYDateFormat), moment(to).format(MDYDateFormat)))
  }, [id, from, to])
  const barChartData = useSelector(({ chartsData }) => chartsData.barChartData)
  const categoryChart = useSelector(({ organization }) => organization.categoryChart)
  const categories = useSelector(({ referential }) => referential.categories)
  const { user } = useSelector(({ identity }) => identity)
  const [open, setOpen] = useState(false)
  const [openCreateModal, setOpenCreateModal] = useState(false)
  const [openDownloadModal, setOpenDownloadModal] = useState(false)
  const [orderLicenseIsOpen, setOrderLicenseIsOpen] = useState(false)
  const isAdmin = user?.role?.includes(AdminRole)

  return (
    <>
      <SlideBar isOpen={openCreateModal} handleClose={() => setOpenCreateModal(false)}>
        <AffectLicenses setOrderLicenseIsOpen={setOrderLicenseIsOpen} title={Text.addLearnerTitle} setOpenAffectLicensesModal={setOpenCreateModal} showList />
      </SlideBar>
      <SlideBar isOpen={openDownloadModal} handleClose={() => setOpenDownloadModal(false)}>
        <DownloadLearnersReport />
      </SlideBar>
      <SlideBar isOpen={orderLicenseIsOpen} handleClose={() => setOrderLicenseIsOpen(false)}>
        <OrderLicenses setOpenOrderLicenses={setOrderLicenseIsOpen} />
      </SlideBar>
      <div className={containerActions}>
        <div className={firstPart}>
          <div className={paperDate}>
            <TypographyElement
              component='p'
              fontSize='13px'
              lineHeight='16px'
              color={neutral[6]}
              handleClick={() => setOpen(true)}
            >
              {dateToStringFormat(from, to)}
              <Icon iconName={Icons.calendar} style={calendarIconClass} />
            </TypographyElement>
          </div>
          <DateRange
            open={open}
            setOpen={setOpen}
            handleChange={handleSelect}
            rangeColors={blue[0]}
            selectionRange={{ startDate: from, endDate: to, key: 'selection' }}
          />
        </div>
        {
          showLearnerActions
            ? (
              <div
                className={downloadButton}
                onClick={() => dispatch(downloadUserReportAction(id, fullName))}
              >
                <Icon iconName={Icons.download} style={iconButton} />
                <TypographyElement
                  component='h4'
                  variant='caption1'
                  align='left'
                  color={neutral[6]}
                  fontWeight={500}
                >
                  {Text.downloadReport}
                </TypographyElement>
              </div>)
            : null
        }
        {showLearnersActions
          ? (
            <div className={secondPart}>
              <div
                className={cardStyle}
                onClick={() => setOpenDownloadModal(true)}
              >
                <Icon iconName={Icons.download} style={icon} />
                <TypographyElement
                  component='p'
                  fontSize='13px'
                  lineHeight='16px'
                  spacing='0 0 0 8px'
                  color={neutral[6]}
                >
                  {Text.downloadSuivi}
                </TypographyElement>
                <Icon iconName={Icons.roundedDown} style={arrow} />
              </div>
              {isAdmin
                ? (
                  <Button
                    handleClick={() => setOpenCreateModal(true)}
                    size='big'
                    variation='primary'
                    className={button}
                    label={Text.createLearner}
                    width={200}
                  />)
                : null}
            </div>)
          : null}
      </div>
      <div className={cardsContainer}>
        <InfoCard infoLink={infoLink} width='50%' noKnowMore title={Text.duration}>
          <div className={textClass}>
            <TypographyElement
              variant='heading2'
              color={blue[0]}
              fontWeight='bolder'
              fontSize='60px'
              lineHeight='26px'
              display='inline'
              spacing='0 8px 0 0'
            >
              {ToHours(barChartData.Total)}
            </TypographyElement>
            <TypographyElement
              variant='heading2'
              color={blue[0]}
              fontWeight='bolder'
              fontSize='16px'
              lineHeight='26px'
              display='inline'
              spacing='0 8px 0 0'
            >
              {Text.hours}
            </TypographyElement>
          </div>
          <div className={barChartStyle}>
            <Bar
              data={barChartFormat(secondsToMinsChartFormat(barChartData.ViewsByDate), generateDateTypeAndLabels(moment(from), moment(to)))}
              options={options}
            />
          </div>
        </InfoCard>
        <InfoCard infoLink={infoLink} width='50%' noKnowMore title={Text.work}>
          <div className={ChartSkeletonStyle}>
            <Radar
              data={radarChartFormat(categoryChart)}
              options={radarOptions}
              plugins={[radarPlugin(categories)]}
              height={350}
            />
          </div>
        </InfoCard>
      </div>
    </>
  )
}
