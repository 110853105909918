export default {
  searchBar: {
    width: 300
  },
  headerContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: '48px 0 24px',
    minWidth: 900
  },
  firstPart: {
    width: '48%',
    display: 'flex',
    alignItems: 'center'
  }
}
