import { neutral, blue } from '@alphorm/ui'

export default {
  icon: {
    fontSize: 20,
    color: blue[0],
    cursor: 'pointer',
    lineHeight: '12px'
  },
  iconContainer: {
    margin: '42px 0',
    border: `.5px solid ${neutral[1]}`,
    width: 40,
    height: 38,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    cursor: 'pointer'
  }
}
