
import { createUseStyles } from 'react-jss'
import style from '../style'
import { amazonBucket, DMYDateFormat } from 'constants/'
import Text from '../text.json'
import {
  TypographyElement,
  Avatar,
  neutral
} from '@alphorm/ui'
import { avatarName } from 'helpers'
import moment from 'moment'
import { useSelector } from 'react-redux'

const useStyle = createUseStyles(style)

export const NameRow = ({ value }) => {
  const {
    avatarContainer,
    fontStyle
  } = useStyle()
  const { loading } = useSelector(state => state.config)
  const name = `${value.FirstName} ${value.LastName}`
  return (
    <div className={avatarContainer}>
      <div className={fontStyle}>
        <div className={avatarContainer}>
          <Avatar
            loading={loading}
            key={loading}
            img={value?.Photo
              ? `${amazonBucket.bucketBaseUrl}${value?.Photo}`
              : null}
            name={avatarName(name)}
            size='size_0'
          />
        </div>
        <TypographyElement
          fontWeight={600}
          fontSize='16px'
          lineHeight='20px'
          spacing='0px 0px 0px 8px'
          color={neutral[6]}
        >
          {name}
        </TypographyElement>
      </div>
    </div>
  )
}

export const EmailRow = ({ value }) => {
  return (
    <TypographyElement
      fontWeight={600}
      fontSize='16px'
      lineHeight='20px'
      color={neutral[6]}
    >
      {value.Email || ''}
    </TypographyElement>
  )
}

export const LastConnectionDateRow = ({ item }) => {
  return (
    <TypographyElement
      fontWeight={600}
      fontSize='16px'
      lineHeight='20px'
      color={neutral[6]}
    >
      {item ? moment(item).format(DMYDateFormat) : Text.never}
    </TypographyElement>
  )
}

export const CreationDateRow = ({ item }) => {
  return (
    <TypographyElement
      fontWeight={600}
      fontSize='16px'
      lineHeight='20px'
      color={neutral[6]}
    >
      {item ? moment(item).format(DMYDateFormat) : Text.noDate}
    </TypographyElement>
  )
}
