export default {
  cards: {
    border: '1px solid #F0F5F9',
    borderRadius: 4,
    marginBottom: 16
  },
  container: {
    display: 'flex',
    flexDirection: 'column'
  }
}
