export default [
  {
    accessType: 'Navigateur (Chrome)',
    place: 'France (196.00.000.134)',
    date: '05:33 (il y a 4 minutes)',
    time: '3 heures'
  },
  {
    accessType: 'Navigateur (Chrome)',
    place: 'France (196.00.000.134)',
    date: '05:33 (il y a 4 minutes)',
    time: '3 heures'
  },
  {
    accessType: 'Navigateur (Chrome)',
    place: 'France (196.00.000.134)',
    date: '05:33 (il y a 4 minutes)',
    time: '3 heures'
  },
  {
    accessType: 'Navigateur (Chrome)',
    place: 'France (196.00.000.134)',
    date: '05:33 (il y a 4 minutes)',
    time: '3 heures'
  },
  {
    accessType: 'Navigateur (Chrome)',
    place: 'France (196.00.000.134)',
    date: '05:33 (il y a 4 minutes)',
    time: '3 heures'
  }
]
