import Layout from 'components/Common/Layout'
import ReportChartContainer from 'components/ReportChartContainer'
import ReportAffectationHistoryContainer from 'components/ReportAffectationHistoryContainer'
import { useDispatch, useSelector } from 'react-redux'
import EmptyPage from 'components/EmptyPage'
import Text from 'components/ReportChartContainer/text.json'
import { createUseStyles } from 'react-jss'
import style from './style'
import { TypographyElement, Icon, Icons, neutral, Button } from '@alphorm/ui'
import { DMYDateFormat, helpLinks, MDYDateFormat } from 'constants/'
import { help } from 'assets'
import { useEffect, useState } from 'react'
import { TurnOnLoaderAction } from 'store/config'
import { downloadHistoryReportAction, fetchLicenseInfoAction, fetchOrganizationTotalLearnersAction } from 'pages/Home/store'
import moment from 'moment'
import SlideBar from 'components/Common/SlideBar'
import OrderLicenses from 'components/InviteBar/OrderLicenses'

const useStyle = createUseStyles(style)
const now = moment()._d
const yearAgo = moment(now).subtract(1, 'Y')._d

export default () => {
  const dispatch = useDispatch()
  const { titleClass, linkHelp, iconHelp, headerContainer, cardStyle, icon, paperDate, calendarIconClass, ctaContainer } = useStyle()
  const [openOrderLicenses, setOpenOrderLicenses] = useState(false)
  const [open, setOpen] = useState(false)
  const [from, setFromDate] = useState(yearAgo)
  const [to, setToDate] = useState(now)
  const { licenseInfo, lastAffectedLearners } = useSelector(state => state.organization)
  useEffect(() => {
    dispatch(TurnOnLoaderAction())
    dispatch(fetchLicenseInfoAction())
    dispatch(fetchOrganizationTotalLearnersAction())
  }, [])
  return (
    <Layout noInviteBar>
      <SlideBar isOpen={openOrderLicenses} handleClose={() => setOpenOrderLicenses(false)}>
        <OrderLicenses setOpenOrderLicenses={setOpenOrderLicenses} />
      </SlideBar>
      <TypographyElement
        component='h2'
        variant='heading2'
        align='left'
        spacing='42px 0 30px 0'
        display='flex'
        className={titleClass}
      >
        {Text.reports}
        <a rel='noopener noreferrer' target='_blank' href={helpLinks.report} className={linkHelp}><img src={help} alt='help' className={iconHelp} /></a>
      </TypographyElement>
      <div className={headerContainer}>
        <div onClick={() => setOpen(true)}>
          <div className={paperDate}>
            <TypographyElement
              component='p'
              fontSize='13px'
              lineHeight='16px'
              color={neutral[6]}
            >
              {`${moment(from).format(DMYDateFormat)} - ${moment(to).format(DMYDateFormat)}`}
              <Icon iconName={Icons.calendar} style={calendarIconClass} />
            </TypographyElement>
          </div>
        </div>
        <div className={ctaContainer}>
          {
            lastAffectedLearners.Total
              ? (
                <div
                  className={cardStyle}
                  onClick={() => dispatch(downloadHistoryReportAction(moment(from).format(MDYDateFormat), moment(to).format(MDYDateFormat)))}
                >
                  <Icon iconName={Icons.download} style={icon} />
                  <TypographyElement
                    component='p'
                    fontSize='13px'
                    lineHeight='16px'
                    spacing='0 0 0 8px'
                    color={neutral[6]}
                  >
                    {Text.download}
                  </TypographyElement>
                </div>)
              : null
          }
          <Button
            handleClick={() => setOpenOrderLicenses(true)}
            size='big'
            variation='primary'
            marginButton='0 0 0 10px'
            label={Text.orderLicenses}
            width={240}
          />
        </div>
      </div>
      {
        licenseInfo?.OrganizationLicenseDetails?.length
          ? (
            <>
              <ReportChartContainer />
              <ReportAffectationHistoryContainer open={open} setOpen={setOpen} from={from} to={to} setFromDate={setFromDate} setToDate={setToDate} />
            </>)
          : <EmptyPage report />
      }

    </Layout>
  )
}
