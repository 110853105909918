import { put, takeLatest } from 'redux-saga/effects'
import { fetch } from '@alphorm/js'
import { oidcUserManager } from 'identity'
import { BASE_URL_WEB, notification } from 'constants/'
import { openBannerAction } from 'components/Banner/store'
import Text from './text.json'
import { TurnOffLoaderAction, TurnOnLoaderAction } from 'store/config'

const initialUserState = {
  error: false,
  count: 0,
  data: []
}

export const reducer = (state = initialUserState, { type, data }) => {
  switch (type) {
    case GET_ADMINS_SUCCESS:
      return {
        ...state,
        ...data
      }
    case GET_ADMINS_FAILED:
      return {
        ...state,
        error: data
      }
    case ADD_ADMINS_SUCCESS:
      return {
        ...state,
        ...data
      }
    case ADD_ADMINS_FAILED:
      return {
        ...state,
        error: data
      }
    default:
      return state
  }
}

export const fetchAdminsAction = (field, asc) => ({ type: ADMINS_FETCH_REQUESTED, field, asc })
export const addAdminsAction = (AdminsEmail) => ({ type: ADD_ADMINS_REQUESTED, AdminsEmail })

function * fetchAdmins ({ field, asc }) {
  yield put(TurnOnLoaderAction())
  try {
    const { FilteredCount } = yield fetch(queries.getAdminCount, { userManager: oidcUserManager })
    const response = yield fetch(queries.getAdmins(FilteredCount, field, asc), { userManager: oidcUserManager })
    yield put({ type: GET_ADMINS_SUCCESS, data: { data: response.Items, count: response.FilteredCount } })
    yield put(TurnOffLoaderAction())
  } catch (err) {
    yield put({ type: GET_ADMINS_FAILED, data: err })
    yield put(TurnOffLoaderAction())
  }
}

function * addAdminsToTeam ({ AdminsEmail }) {
  try {
    const body = {
      AdminsEmail
    }
    yield fetch(queries.addAdmin, { method: 'post', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(body), nojson: true, userManager: oidcUserManager })
    yield put({ type: ADD_ADMINS_SUCCESS })
    yield put(openBannerAction(Text.addAdminSuccess, notification.success))
  } catch (err) {
    yield put({ type: ADD_ADMINS_FAILED, data: err })
    yield put(openBannerAction(Text.addAdminError, notification.failed))
  }
}

export function * AdminsRootSaga () {
  yield takeLatest(ADMINS_FETCH_REQUESTED, fetchAdmins)
  yield takeLatest(ADD_ADMINS_REQUESTED, addAdminsToTeam)
}

const queries = {
  getAdminCount: `${BASE_URL_WEB}/Admin`,
  getAdmins: (take, field, asc) => `${BASE_URL_WEB}/Admin?Take=${take}&Skip=0${field ? `&OrderBy=${field}` : ''}${asc ? `&OrderedByAsc=${asc}` : ''}`,
  addAdmin: `${BASE_URL_WEB}/Organization/addAdmin`
}

const ADMINS_FETCH_REQUESTED = 'ADMINS_FETCH_REQUESTED'
const GET_ADMINS_SUCCESS = 'GET_ADMINS_SUCCESS'
const GET_ADMINS_FAILED = 'GET_ADMINS_FAILED'
const ADD_ADMINS_REQUESTED = 'ADD_ADMINS_REQUESTED'
const ADD_ADMINS_SUCCESS = 'ADD_ADMINS_SUCCESS'
const ADD_ADMINS_FAILED = 'ADD_ADMINS_FAILED'
