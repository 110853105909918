export default {
  cardsContainer: {
    display: 'flex',
    marginTop: 24,
    columnGap: 20
  },
  ChartSkeletonStyle: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100%'
  }
}
