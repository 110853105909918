import style from './style'
import { createUseStyles } from 'react-jss'
import HorizantalCard from 'components/HorizantalCard'
import { Skeleton } from '@alphorm/ui'

const useStyle = createUseStyles(style)

export default () => {
  const {
    controls,
    coursesContainer
  } = useStyle()
  const skeletonCoursesInProgress = [...Array(3)]
  return (
    <>
      <div className={controls}>
        <Skeleton lines={1} height={45} width={300} />
      </div>
      <div className={coursesContainer}>
        {skeletonCoursesInProgress.map((_, index) => {
          return (
            <div key={index}>
              <HorizantalCard loading />
            </div>
          )
        })}
      </div>
    </>

  )
}
