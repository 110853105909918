export default {
  view: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginBottom: 48
  },
  root: {
    marginLeft: 'unset !important',
    marginRight: 'unset !important',
    maxWidth: 'unset !important'
  }
}
