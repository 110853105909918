import {
  Row,
  TypographyElement,
  Button,
  Col,
  SearchableSelectList
} from '@alphorm/ui'
import { useState } from 'react'
import { createUseStyles } from 'react-jss'
import style from './style'
import Text from './text.json'
import { useDispatch } from 'react-redux'
import { fetchLearnersInfosAction, fetchLearnersProgressionAction } from 'pages/Learners/store'
import { scrollUp } from 'helpers'

const useStyle = createUseStyles(style)

export default ({ handleClose }) => {
  const { input, root, header } = useStyle()
  const dispatch = useDispatch()

  const types = [
    { Id: 1, Value: Text.suivi },
    { Id: 2, Value: Text.users }
  ]

  const [selectType, setSelectType] = useState({
    selectedItemId: null,
    selectedItemValue: ''
  })

  const handleExport = () => {
    handleClose && handleClose()
    scrollUp()
    if (selectType?.selectedItemId === types[0].Id) {
      dispatch(fetchLearnersProgressionAction())
    } else if (selectType?.selectedItemId === types[1].Id) {
      dispatch(fetchLearnersInfosAction())
    }
  }

  return (
    <div className={root}>
      <div className={header}>
        <Col pos='left' className='col' grid={9}>
          <TypographyElement
            component='h3'
            variant='heading3'
            align='left'
          >
            {Text.export}
          </TypographyElement>
        </Col>
      </div>
      <Row justify='left'>
        <span className={input}>
          <SearchableSelectList
            id='reportType'
            label={Text.type}
            items={types}
            placeholder={Text.placeholder}
            selectedItem={selectType?.selectedItemValue}
            onSelectedItem={(item) => {
              setSelectType({
                selectedItemValue: item.Value,
                selectedItemId: item.Id
              })
            }}
            handleChange={(e) => setSelectType({
              selectedItemValue: e.target.value,
              toggleSuggestionList: (e.target.value !== '')
            })}
          />
        </span>
        <Button
          marginButton='24px 0 0 0'
          label={Text.download}
          width='100%'
          disabled={!selectType?.selectedItemValue}
          handleClick={handleExport}
        />
      </Row>
    </div>
  )
}
