import { createUseStyles } from 'react-jss'
import style from './style'
import {
  Icon,
  Icons,
  Avatar,
  Skeleton,
  AvatarSize
} from '@alphorm/ui'

const useStyle = createUseStyles(style)

export default () => {
  const {
    icon,
    iconContainer,
    teamInfosSkeleton
  } = useStyle()
  return (
    <>
      <div className={iconContainer}>
        <div><Icon iconName={Icons.roundedLeft} style={icon} /></div>
        <div className={teamInfosSkeleton}>
          <Avatar loading size={AvatarSize.size_1} />
          <Skeleton lines={1} height={45} width={250} margin='0 0 0 20px' />
        </div>
      </div>
      <Skeleton lines={2} height={20} width={['20%', '40%']} margin='16px 0px 0px 35px' />
    </>
  )
}
