import { neutral, green } from '@alphorm/ui'
export default {
  paperStyle: {
    display: 'flex',
    alignItems: 'center',
    backgroundColor: neutral[0],
    height: 64,
    margin: '16px 0',
    padding: '11px 16px',
    justifyContent: 'space-between'
  },
  image: {
    width: 150,
    margin: '0 0 26px 0'
  },
  iconClass: {
    fontSize: 24,
    lineHeight: '10px',
    color: green[2]
  },
  logo: {
    width: 50,
    margin: '0 27px 0 0'
  },
  stepsContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  }
}
