export default [
  {
    id: 1,
    author: 'Aymen Graoui',
    action: ' à crée l’équipe',
    link: ' Développement',
    image: 'https://static.alphorm.com/dev/upload/9f8f4041-acfe-4fcb-99b1-0545a6d35d1e.jpeg'
  },
  {
    id: 2,
    author: 'Manal Drissi',
    action: ' à crée l’équipe',
    link: ' Développement',
    image: 'https://static.alphorm.com/dev/upload/9f8f4041-acfe-4fcb-99b1-0545a6d35d1e.jpeg'
  },
  {
    id: 3,
    author: 'Marouane Ait Mensour',
    action: ' à crée l’équipe',
    link: ' Project Manager',
    image: 'https://static.alphorm.com/dev/upload/9f8f4041-acfe-4fcb-99b1-0545a6d35d1e.jpeg'
  }
]
