import Layout from 'components/Common/Layout'
import ProfileInfo from 'components/ProfileInfo'
import { useDispatch, useSelector } from 'react-redux'
import { useEffect } from 'react'
import { useParams } from 'react-router'
import Recommendation from 'components/Recommendation'
import LearnerCoursesCard from 'components/LearnerCoursesCard'
import { fetchLearnerCourseInProgressAction, fetchLearnerProfileAction, fetchRecommendedCoursesAction } from './store'
import ChartsSection from 'components/ChartsSection'
import { helpLinks, totalViewQueryFields } from 'constants/'
import EmptyPage from 'components/EmptyPage'
import Text from './text'
import GoBackAction from 'components/Common/GoBackAction'
import AlertBanner from 'components/Common/AlertBanner'
import moment from 'moment'
import { TurnOnLoaderAction } from 'store/config'
import ProfileInfoSkeleton from 'components/ProfileInfoSkeleton'
import ChartsSectionSkeleton from 'components/ChartsSectionSkeleton'
import LearnerCoursesCardSkeleton from 'components/LearnerCoursesCardSkeleton'
import RecommendationSkeleton from 'components/RecommendationSkeleton'
import { createUseStyles } from 'react-jss'
import style from './style'

const useStyle = createUseStyles(style)

export default () => {
  const dispatch = useDispatch()
  const { chartSection } = useStyle()
  const { id } = useParams()
  const {
    FirstName,
    LastName,
    Email,
    LastConnectionDate,
    EndDate,
    IsActive,
    Photo,
    OrganizationLicenseType,
    TeamId,
    TeamImage,
    TeamName,
    coursesInProgress,
    data
  } = useSelector(state => state.profile)
  const { isManager } = useSelector(({ organizationProfile }) => organizationProfile)
  useEffect(() => {
    dispatch(TurnOnLoaderAction())
    dispatch(fetchLearnerProfileAction(id))
    dispatch(fetchLearnerCourseInProgressAction(id))
    dispatch(fetchRecommendedCoursesAction(id))
  }, [id])
  const { loading } = useSelector(state => state.config)
  return (
    <Layout noInviteBar>
      <GoBackAction />
      {
        loading
          ? (
            <>
              <ProfileInfoSkeleton />
              <div className={chartSection}>
                <ChartsSectionSkeleton />
              </div>
              <LearnerCoursesCardSkeleton />
              <RecommendationSkeleton />
            </>
            )
          : (
            <>
              <ProfileInfo
                size='size_4'
                TeamId={TeamId}
                TeamImage={TeamImage}
                TeamName={TeamName}
                Name={`${FirstName} ${LastName}`}
                Email={Email}
                manager={isManager}
                LastConnectionDate={LastConnectionDate}
                EndDate={EndDate}
                IsActive={IsActive}
                Photo={Photo}
                OrganizationLicenseType={OrganizationLicenseType}
              />
              {(moment(EndDate).isAfter(moment()) || coursesInProgress?.Count)
                ? (
                  <>
                    {!coursesInProgress?.Count && <AlertBanner message={Text.bannerText} />}
                    {coursesInProgress?.Count ? <ChartsSection id={id} field={totalViewQueryFields.user} infoLink={helpLinks.learnerProfile} showLearnerActions fullName={`${FirstName} ${LastName}`} /> : null}
                    <LearnerCoursesCard fullName={`${FirstName} ${LastName}`} />
                    <Recommendation
                      FirstName={FirstName}
                      LastName={LastName}
                      data={data}
                    />
                  </>)
                : <EmptyPage
                    Title={Text.Title}
                    SubTitle={Text.SubTitle}
                    manager
                  />}
            </>
            )
      }

    </Layout>
  )
}
