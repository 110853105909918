import {
  blue
} from '@alphorm/ui'

export default {
  root: {
    userSelect: 'none',
    maxWidth: 380,
    minWidth: 300
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
    flexDirection: 'column',
    marginBottom: 20
  },
  quantity: {
    marginBottom: 16,
    display: 'flex',
    alignItems: 'flex-start',
    gap: 10
  },
  quantityButtonsStyle: {
    overflow: 'unset !important'
  },
  licenseTypeContainer: {
    width: '100%',
    marginTop: 16
  },
  tipStyle: {
    display: 'flex',
    flexDirection: 'column',
    position: 'absolute !important',
    left: 0,
    bottom: '1vh',
    margin: 16,
    padding: 10
  },
  spanStyle: {
    color: blue[0],
    textDecoration: 'none'
  },
  IntercomSpanStyle: {
    color: blue[0],
    textDecoration: 'none',
    cursor: 'pointer'
  },
  messageContainer: {
    display: 'flex',
    textDecoration: 'none',
    padding: 16,
    alignItems: 'center'
  },
  helpIconClass: {
    width: 26,
    cursor: 'pointer'
  }
}
