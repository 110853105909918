
import { createUseStyles } from 'react-jss'
import style from '../style'
import Text from '../text.json'
import {
  TypographyElement,
  neutral,
  red
} from '@alphorm/ui'
import { useDispatch, useSelector } from 'react-redux'
import { teamDev } from 'assets'
import { Popover, PopoverContent, PopoverTrigger } from 'components/Common/PopOver'
import SlideBar from 'components/Common/SlideBar'
import EditModal from 'components/EditModal'
import AssignManager from 'components/InviteBar/Team/assignManager'
import RemoveManager from 'components/InviteBar/Team/removeManager'
import RemoveTeam from 'components/InviteBar/Team/removeTeam'
import { useState } from 'react'
import { fetchTeamAction } from 'pages/TeamDetails/store'

const useStyle = createUseStyles(style)

export const NameRow = ({ value }) => {
  const {
    avatarContainer,
    teamTag
  } = useStyle()
  return (
    <div className={avatarContainer}>
      <img src={value.Image || teamDev} alt='' className={teamTag} />
      <TypographyElement
        fontWeight={600}
        fontSize='16px'
        lineHeight='20px'
        spacing='0px 0px 0px 8px'
        color={neutral[6]}
      >
        {value.Name && value.Name.length > 16 ? value.Name.slice(0, 16 - 1) + '…' : value.Name}
      </TypographyElement>
    </div>
  )
}

export const TeamCountRow = ({ item }) => {
  return (
    <TypographyElement
      fontWeight={600}
      fontSize='16px'
      lineHeight='20px'
      color={neutral[6]}
    >
      {item}
    </TypographyElement>
  )
}

export const ManagersRow = ({ item, value }) => {
  const { loading } = useSelector(state => state.config)
  const {
    cellContainer,
    dotsPopoverContainer,
    dot,
    popOverContent,
    linkTextStyle,
    endRowContainer,
    disabledLinkTextStyle,
    linkContainer,
    disabledLinkContainer
  } = useStyle()
  const { managersList: allManagers } = useSelector(state => state.teamDetails)
  const team = useSelector(state => state.teamDetails)
  const popOverContentMap = [
    {
      text: Text.editTeam,
      onHandleClick: () => setOpenEditTeamModal(true)
    },
    {
      text: Text.assignManager,
      onHandleClick: () => allManagers?.length && (item?.length !== allManagers?.length) ? setAssignManagerSideBar(true) : null,
      className: allManagers?.length && (item?.length !== allManagers?.length) ? linkTextStyle : disabledLinkTextStyle,
      containerClassName: allManagers?.length && (item?.length !== allManagers?.length) ? linkContainer : disabledLinkContainer,
      color: allManagers?.length && (item?.length !== allManagers?.length) ? neutral[6] : neutral[2]
    },
    {
      text: Text.removeManager,
      onHandleClick: () => item?.length ? setRemoveManagerSideBar(true) : null,
      className: item?.length ? linkTextStyle : disabledLinkTextStyle,
      containerClassName: item?.length ? linkContainer : disabledLinkContainer,
      color: item?.length ? neutral[6] : neutral[2]
    },
    {
      text: Text.deleteTeam,
      onHandleClick: () => setRemoveTeamSideBar(true),
      color: red[3]
    }
  ]
  const [openEditTeamModal, setOpenEditTeamModal] = useState(false)
  const [assignManagerSideBar, setAssignManagerSideBar] = useState(false)
  const [removeManagerSideBar, setRemoveManagerSideBar] = useState(false)
  const [removeTeamSideBar, setRemoveTeamSideBar] = useState(false)
  const slideBarMap = [
    {
      isOpen: openEditTeamModal,
      children: <EditModal closeModal={() => setOpenEditTeamModal(false)} teamName={team?.Name} teamIcon={team?.Image} description={team?.Description} />,
      handleClose: () => setOpenEditTeamModal(false)
    },
    {
      isOpen: assignManagerSideBar,
      children: <AssignManager handleClose={() => setAssignManagerSideBar(false)} team={team} />,
      handleClose: () => setAssignManagerSideBar(false)
    },
    {
      isOpen: removeManagerSideBar,
      children: <RemoveManager handleClose={() => setRemoveManagerSideBar(false)} team={team} />,
      handleClose: () => setRemoveManagerSideBar(false)
    },
    {
      isOpen: removeTeamSideBar,
      children: <RemoveTeam team={team} handleClose={() => setRemoveTeamSideBar(false)} />,
      handleClose: () => setRemoveTeamSideBar(false)
    }
  ]
  const { isManager } = useSelector(({ organizationProfile }) => organizationProfile)
  const dispatch = useDispatch()
  const formattedManagers = !loading && item.map(manager => `${manager.FirstName} ${manager.LastName}`)
  return (
    <div className={endRowContainer}>
      {slideBarMap.map((item, index) => (<SlideBar key={index} {...item} />))}
      <TypographyElement
        fontWeight={600}
        fontSize='16px'
        lineHeight='20px'
        color={neutral[6]}
      >
        {item.length
          ? formattedManagers.slice(0, 3).join(', ')
          : Text.noManager}
        {formattedManagers.length > 3 && '…'}
      </TypographyElement>
      {!isManager
        ? (
          <div className={cellContainer}>
            <Popover>
              <PopoverTrigger>
                <div className={dotsPopoverContainer} data-div='modal'>
                  <span className={dot} data-div='modal' />
                  <span className={dot} data-div='modal' />
                  <span className={dot} data-div='modal' />
                </div>
              </PopoverTrigger>
              <PopoverContent topPosition={40}>
                <div className={popOverContent}>
                  {popOverContentMap.map((item, index) => (
                    <div
                      key={index}
                      className={item.containerClassName || linkContainer}
                      onClick={(e) => {
                        e.stopPropagation()
                        dispatch(fetchTeamAction(value.id))
                        item.onHandleClick()
                      }}
                    >
                      <TypographyElement
                        key={index}
                        color={item.color || neutral[6]}
                        fontWeight='normal'
                        fontSize='13px'
                        lineHeight='16px'
                        className={item.className || linkTextStyle}
                      >
                        {item.text}
                      </TypographyElement>
                    </div>
                  ))}
                </div>
              </PopoverContent>
            </Popover>
          </div>)
        : null}
    </div>
  )
}
