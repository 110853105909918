import { blue } from '@alphorm/ui'
export default {
  icon: {
    fontSize: 20,
    color: blue[0],
    cursor: 'pointer'
  },
  iconContainer: {
    marginTop: 42,
    marginBottom: 32
  }
}
